export default function addToCampaignsAction(params, selectedBefore = null) {
  var selected = [];
  if (!selectedBefore) {
    const list = this.dataGet(params.prefix);
    selected = list.selected;
  } else {
    selected = params.selected;
  }

  const relateData = {
    module: "acm_CampaignsAction",
  };
  this.openRelatePopup(relateData, (returnData) => {
    this.popupHide();
    var sendRestData = {
      contacts_uids: selected,
      campaigns_actions_uids: typeof returnData.id === "string" ? [returnData.id] : returnData.id,
    };
    this.load();
    this.rest.post("addToCampaignsAction", sendRestData, (returnRestData) => {
      this.unLoad();
      if (returnRestData) {
        this.alert("OK. Přidáno nových kontaktů: "+returnRestData.new_contacts_count);
      } else {
        // nastala chyba
      }
    });
  });
}


