export default function raiseFileUpload(params) {
   this.processFileInput({}, file => {
       this.dsClear();
       const prefix = params.prefix;
       let reader = new FileReader();
       this.temporaryData = {file: file};
       reader.onload = (e) => {
           if (reader.result !== null) {
               this.dsAdd('set', prefix + '/fileContents', reader.result);
               this.dsProcess();
               this.parseFile({prefix: prefix});
           }
       }
       reader.readAsText(file[0]);
    })
}