export default function adminMessage(data,callback) {
    const action = data.action
    if(action == "setStorage"){
        data.data.forEachObject((data,key) => {
            this.setStorage(key,data)
        })
    }else if(action == "updateCssFromStorage"){
        this.overwriteStyle();
    }else if(action == "getStorage"){
        const storage = this.getStorage(data.data.key);
        callback(storage);
    }
    if (action == "setStyle") {
      
    }
}