import React from "react";
import MaterialDefault from "./MaterialDefault";
import SelectWindow from "./SelectWindow";
import FormControl from "@material-ui/core/FormControl";
export default class TabSelect extends MaterialDefault {
    constructor(props) {
        super(props);
        var open = false;
        this.state = {
            focus: false,
            openOnFocus: true,
            open: open,
            label: ''
        };
    }
    onChange(e) {
        this.setState({
            value: e,
            open: false,
            label: e
        });
        if (this.props.onChange != undefined) {
            this.props.onChange(e);
        }
    }
    onClose(e) {
        this.setState({ open: false });
        setTimeout(() => { }, 100);
        if (this.props.onClose != undefined) {
            this.props.onClose(e);
        }
    }
    onOpen(e) {
        this.setState({ open: true });
        if (this.props.onOpen != undefined) {
            this.props.onOpen(e);
        }
    }
    onBlur(e) {
        if (this.props.onBlur != undefined) {
            this.props.onBlur(e);
        }
    }
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.open === false) {
            this.setState({ open: true })
        }

        if (this.props.onKeyDown != undefined) {
            this.props.onKeyDown(e);
        }
    }
    onClick(e) {
        this.setState({ open: true })
    }
    onFocus(e) {
        if (this.props.onFocus !== undefined) {
            this.props.onFocus(e);
        }
    }
    render() {
        const options = this.props.options;

        var className = "acmSelectContainer";
        if (this.state.focus == true) {
            className = "acmSelectContainer focus";
        }
        if (this.props.error == true) {
            className += " error";
        }

        var selectWindow = (<SelectWindow
            className="acmSelect"
            id={this.props.id}
            inputProps={{ id: this.props.id }}
            onChange={e => this.onChange(e)}
            onOpen={e => this.onOpen(e)}
            onClose={e => this.onClose(e)}
            onBlur={e => this.onBlur(e)}
            // onKeyDown={e => this.onKeyDown(e)}
            onFocus={e => this.onFocus(e)}
            onClick={e => this.onChange(e)}
            inputRef={this.props.myRef}
            open={this.state.open}
            autoFocus={this.props.autoFocus}
            name={this.props.name}
            options={options}
            open={this.state.open}
            defaultValue={this.props.defaultValue}
            activeTabName={this.props.activeTabName || ''}
        />);

        return (
            <FormControl
                className={
                    this.props.containerClassName != undefined
                        ? className + " " + this.props.containerClassName
                        : className
                }
                disabled={this.props.disabled}
                onKeyDown={e => this.onKeyDown(e)}
                onClick={() => this.state.open == false ? this.onClick() : null}
                autoFocus={this.props.autoFocus}
            >
                {selectWindow}
            </FormControl>
        );
    }
}
