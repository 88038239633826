import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import MultiSelect from "../../formElements/MultiSelect";
import Checkbox from "../../formElements/CheckBox";
import  sAction  from "sAction";
import RolesTable from "../roles/RolesTable";
import TabContainer from "../../formElements/TabContainer";

export default class ReportWindowSave extends PureComponent {
  constructor(props) {
    super(props);

    var selectedUsers = this.props.data.metadata.selectedUsers ? this.props.data.metadata.selectedUsers : [];
    var selectedRoles = this.props.data.metadata.selectedRoles ? this.props.data.metadata.selectedRoles : [];
    var show = [];
    var category = "";
    if (props.data.metadata != null) {
      show = props.data.metadata.show;
      category = props.data.metadata.cat;
    }
    this.state = {
      show,
      category: category || "report",
      selectedUsers: selectedUsers,
      selectedRoles: selectedRoles,
      activeTab: 0
    };

    this.name = React.createRef();
    this.description = React.createRef();
    this.id = React.createRef();
  }

  changeActiveTab(newTab) {
    if (newTab == this.state.activeTab) {
      return;
    }
    this.setState({ activeTab: newTab });
  }
  updateData = (name, value) => {
    this.setState({ [name]: value });
  };
  save(asNew = false) {
    const propsData = this.props.data;
    const nameInput = this.name.current;
    const idInput = this.id.current;
    const name = nameInput.value;
    const id = asNew ? null : idInput.value;
    const cat = this.state.category;
    const description = this.description.current.value;
    var admin_only = 0;
    var selectedUsers = [];
    var selectedRoles = [];
    if (sAction.userPreferences.is_admin == "1") {
        admin_only = propsData.metadata.admin_only ? 1 : 0;
        selectedUsers = admin_only == 1 ? [] : this.state.selectedUsers;
        selectedRoles = admin_only == 1 ? [] : this.state.selectedRoles;
    }
    const data = {
      name,
      cat,
      show: this.state.show,
      id,
      description,
      admin_only,
      selectedUsers: selectedUsers,
      selectedRoles: selectedRoles,
    };
    sAction.reportWindowSaveSearch(data);
  }
  delete() {
    sAction.reportWindowDeleteSearch(this.id.current.value);
  }
  changeAdminOnly(event) {
    sAction.dataSet("reportWindow/metadata/admin_only", event ? 1 : 0);
  }

  render() {
    const { selectedUsers, selectedRoles, activeTab } = this.state;
    const data = this.props.data;
    var name = null;
    var id = null;
    var description = null;
    var category = null;
    var admin_only = 0;
    if (data.metadata != null) {
      name = data.metadata.name;
      id = data.metadata.id;
      category = data.metadata.cat;
      description = data.metadata.description;
      if (data.metadata.admin_only != undefined) {
        admin_only = data.metadata.admin_only ? 1 : 0;
      }
    }
    if (!category) category = "report";
    var showValue = this.state.show;
    if (typeof showValue === "object" && showValue != null) {
      try {
        showValue = showValue.toJS();
      } catch (e) { }
    }

    const categoryOptions = sAction.getEnumForSelect("savedSearchCategory");
    const showInOptions = sAction.getEnumForSelect("savedSearchShowIn");

    var saveButton = null;
    var deleteButton = null;
    if (data.metadata.id === null || sAction.userPreferences.is_admin == "1" || data.metadata.canEdit === true) {
      saveButton = (
        <Button onClick={() => this.save(false)}>
          <div
            className={"actionPanelButtonIcon " + "icon-saveIcon"}
          />
          {sAction.translate("LBL_REPORT_SAVE")}
        </Button>
      );
      deleteButton = (
        <Button onClick={() => this.delete()} className='hoverRed'>
          <div
            className={"actionPanelButtonIcon " + "icon-deleteIcon"}
          />
          {sAction.translate("LBL_REPORT_DELETE")}
        </Button>
      );
    }

    const roles = sAction.translate("LBL_ROLES", "Home");
    const users = sAction.translate("LBL_USERS", "Home");
    const tabs = [roles, users]; //"Role", "Uživatelé"
    var userSelection = null;
    let renderMenu = (
      <TabContainer
        onChange={(e, value) => this.changeActiveTab(value)}
        value={activeTab}
        tabs={tabs}
      />
    );
    if (admin_only !== 1) {
      userSelection = (
        <tr>
          <td className="formlabel">{sAction.translate("LBL_REPORT_USER_SELECTION")}</td>
          <td>
            <div className="acmTabContainer">{renderMenu}</div>
            <div className={activeTab != 0 ? "homeTableHide hidden" : "homeTableHide"}>
              <RolesTable
                module="ACLRoles"
                selectedData={selectedRoles}
                onChange={data => this.updateData("selectedRoles", data)}
              />
            </div>
            <div className={activeTab != 1 ? "homeTableHide hidden" : "homeTableHide"}>
              <RolesTable
                keepSelf={true}
                module="Users"
                selectedData={selectedUsers}
                onChange={data => this.updateData("selectedUsers", data)}
              />
            </div>
          </td>
        </tr>
      );
    }

    return (
      <div className="reportWindowSaveContainer flexCenter">
        <div className="simpleForm">
          <table>
            <tbody>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_REPORT_NAME")}</td>
                <td>
                  <input ref={this.id} type="hidden" defaultValue={id} />
                  <InputText
                    className="withBorder"
                    defaultValue={name}
                    myRef={this.name}
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_REPORT_CATEGORY")}</td>
                <td>
                  <Select
                    containerClassName="withBorder"
                    options={categoryOptions}
                    myRef={this.cat}
                    defaultValue={category}
                    onChange={e => this.setState({ category: e.target.value })}
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_REPORT_ORDER_IN")}</td>
                <td>
                  <MultiSelect
                    className="withBorder"
                    id="reportWindowMultiSelect"
                    options={showInOptions}
                    defaultValue={showValue}
                    onBlur={values => this.setState({ show: values })}
                  />
                </td>
              </tr>
              <tr>
                <td className="formlabel">{sAction.translate("LBL_REPORT_DESCRIPTION")}</td>
                <td>
                  <textarea defaultValue={description} ref={this.description} />
                </td>
              </tr>
              {sAction.userPreferences.is_admin == "1" && (
                <tr>
                  <td className="formlabel">{sAction.translate("LBL_REPORT_SYSTEM_VIEW")}</td>
                  <td>
                    <Checkbox
                      defaultValue={admin_only == true ? 1 : 0}
                      onChange={e => this.changeAdminOnly(e)}
                    />
                  </td>
                </tr>
              )}
              {sAction.userPreferences.is_admin == "1" && userSelection}
              <tr>
                <td colSpan="2">
                  <div className="viewActionPanelButtons flexRight">
                    {deleteButton}
                    {saveButton}
                    <Button onClick={e => this.save(true)}>
                      <div
                        className={"actionPanelButtonIcon " + "icon-saveIcon"}
                      />
                      {sAction.translate("LBL_REPORT_SAVE_AS")}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
