import React from "react";

class DragAndDrop extends React.Component {

    constructor(props) {
        super();
        this.state = {
          startX: null,
          startY: null,
          startObjectX: null,
          startObjectY: null,
          startObjectWidth: null,
          startObjectHeight: null,
          target: null,
          collision: [],
          placeholder: null
        };
        this.addEvent = null
        this.dragAllow = true;
        this.parentScrollValue = 0;
    }
    moveStart(data){
     /* if(this.props.moveStart != undefined){
        this.props.moveStart(data);
      } */
      data.element.parentNode.removeChild(data.element);

    }
    moveEnd(data){
    /*  if(this.props.moveEnd != undefined){
        this.props.moveEnd(data) 
      }*/
      this.state.placeholder.parentNode.insertBefore(data.element, this.state.placeholder);
   //   this.state.placeholder.parentNode.removeChild(this.state.placeholder);
    }
    afterEnd(data){
      if(this.props.afterEnd != undefined){
        this.props.afterEnd(data);
      }
    }
    mouseDown(e) {
      if(this.dragAllow == true){
        var handleClass = this.props.handleElementClass;
        const elementClass = this.props.elementsClass
        if(handleClass == undefined){
            handleClass = elementClass
        }
        var finalHolder = null;
        const target = e.target
        if(target.classList.contains("handleClass")){
            finalHolder = target
        }else{
            parent = target.closest(handleClass);
            if(parent != null){
                finalHolder = parent
            }
        }

        if(finalHolder != null){
        var moveElement = null
        if(handleClass != elementClass){
            moveElement = finalHolder.closest(elementClass)
        }else{
            moveElement = finalHolder
        }
        
        if(moveElement != null){
            this.setMoveEvent(e,moveElement);
        }
        }
        
        if(moveElement == null){
        const resizeHolderClass = this.props.resizeHolder;
        if(resizeHolderClass != undefined){
          const resizeElementClass = this.props.resizeClass;
          if(resizeElementClass != undefined){

            const resizeElement = e.target.closest(resizeElementClass);
            if(resizeElement != null){
              const resizeType = e.target.dataset.resizetype
              if(resizeType == undefined){
                console.log("holder nemá určený resizeType");
              }else{
                this.setResizeEvent(e,resizeElement,resizeType);
              }
            
            }
          }
        }
      }
    }
    }
    setMoveEvent(e, target) {
        var self = this;
        const playground = this.refs.playground;
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        const dragHolderClass = this.props.containersClass.replace(".","");
        const elementClass = this.props.elementsClass.replace(".","");
  

        const moveHolderCoordinates = playground.getBoundingClientRect();
        const targetCoordinates = target.getBoundingClientRect();

        const x = targetCoordinates.x - moveHolderCoordinates.x;
        const y = targetCoordinates.y - moveHolderCoordinates.y;
        const width = target.clientWidth;
        const height = target.clientHeight;
    
        const moveObject = this.refs.moveObject;
        moveObject.style.width = width + "px";
        moveObject.style.height = height + "px";
        moveObject.style.left = x + "px";
        moveObject.style.top = y + "px";
        moveObject.innerHTML = target.innerHTML;
        moveObject.style.display = "flex";
        


        var placeholder = document.createElement("div");
        placeholder.classList.add("reportWindowMovePlaceholder");
        placeholder.style.width =  "0px";
        placeholder.style.height = "0px";
        target.parentNode.insertBefore(placeholder, target);
        this.moveStart({element: target})
        var parent = this.refs.playground.parentElement;
        this.parentScrollValue = parent.scrollTop

        const holders = document.getElementsByClassName(dragHolderClass);
        holders.forEachObject(holder => {
          holder.classList.add("dragAndDropActiveContainer");
        });

        var colisions = this.getColosionArray();

        placeholder.style.width = width + "px";
        placeholder.style.height = height + "px";

        this.setState({
          startX: mouseX,
          startY: mouseY,
          startObjectX: x,
          startObjectY: y,
          target: target,
          collision: colisions,
          placeholder: placeholder,
          dragHolderClass: dragHolderClass
        });

        this.addEvent = function(e){
                    self.mouseMove(e,function(data){
            const startX = self.state.startObjectX;
            const startY = self.state.startObjectY;
            const newX = startX + data.x;
            const newY = startY + data.y;
            const moveObject = self.refs.moveObject;
            moveObject.style.left = newX + "px";
            moveObject.style.top = newY + "px";

            const placeholder = self.state.placeholder;
            const colisions = self.state.collision;
            var posun = 0;
            colisions.forEach((child,key) => {
              if (
                child.x  - 15 < newX &&
                child.endX > newX &&
                child.y - placeholder.clientHeight < newY &&
                child.endY + 20 > newY
              ) {
                child.target.parentNode.insertBefore(placeholder, child.target);
              }
            });
          });
        }
        playground.addEventListener("mousemove", this.addEvent)
      }
      mouseMove(e,callback) {
        const target = this.state.target;
        if (target != null) {
          const left = e.clientX - this.state.startX;

          var parent = this.refs.playground.parentElement;
          const scrollDiferen = this.parentScrollValue - parent.scrollTop
        console.log(scrollDiferen);


          const top = e.clientY - (this.state.startY + scrollDiferen);
          callback({
            x: left,
            y: top
          })
        }
      }
      mouseUp(e) {
        const moveHolder = this.refs.playground;
        const moveObject = this.refs.moveObject;
        const dragHolderClass = this.props.containersClass.replace(".","");
        const holders = document.getElementsByClassName(dragHolderClass);
        holders.forEachObject(holder => {
          holder.classList.remove("dragAndDropActiveContainer");
        });

        moveObject.style.display = "none";
        moveHolder.removeEventListener("mousemove",this.addEvent);
        if (
          this.state.placeholder != null &&
          this.state.placeholder.parentNode != undefined
        ) {
          
            
            this.moveEnd({element:this.state.target,holders:document.getElementsByClassName(dragHolderClass)})
            this.state.placeholder.parentNode.removeChild(this.state.placeholder);
            

        }else if(this.state.startObjectWidth != null){
          this.moveEnd({element:this.state.target,resize:true})
        }
        this.setState({
          target: null,
          startObjectWidth: null,
          startObjectHeight: null,
        });
      }
      setResizeEvent(e,resizeElement,resizeType){
          var self = this;
          const startWidth = resizeElement.clientWidth;
          const startHeight = resizeElement.clientHeight;
          const playground = this.refs.playground;

          const mouseX = e.clientX;
          const mouseY = e.clientY;
          this.setState({
            startX: mouseX,
            startY:mouseY,
            target: resizeElement,
            startObjectWidth: startWidth,
            startObjectHeight: startHeight,
          })
          this.addEvent = function(e) {
            self.mouseMove(e,function(data){
                if(resizeType == "width"){
                  const newWidth = startWidth + data.x;
                  resizeElement.style.width = newWidth+"px";
                }
            });
          };
          playground.addEventListener("mousemove",this.addEvent)


      }
      getColosionArray(){
        const playground = this.refs.playground;
        const elementClass = this.props.elementsClass.replace(".","");
        const dragHolderClass = this.props.containersClass.replace(".","");
        const holders = document.getElementsByClassName(dragHolderClass);
        const moveHolderCoordinates = playground.getBoundingClientRect();

        var colisions = [];
        holders.forEachObject((holder, index) => {
          holder.children.forEachObject(child => {
            if(child.classList.contains(elementClass)){
            
            const childCoordinates = child.getBoundingClientRect();
            const x = childCoordinates.x - moveHolderCoordinates.x;
            const y = childCoordinates.y - moveHolderCoordinates.y;
            const data = {
              target: child,
              x: x,
              endX: x + child.clientWidth,
              y: y,
              endY: y + child.clientHeight
            };
            colisions.push(data);
          }
          });
        });
        return colisions;
      }


    

    render() {
      var style = {
        position:"relative",
        height:"auto",
      }
      if(this.props.minHeight !== false){
        style["minHeight"] = "100%";
      }
    return (
        <div
         ref="playground"
         onMouseDown={e => this.mouseDown(e)}
         onMouseUp={e => this.mouseUp(e)}
         style={style}
         >
        {this.props.children}
        <div ref="moveObject" className={"reportWindowMoveObject "+this.props.moveObjectClass} />
        </div>
    )
  }
}
export default DragAndDrop;
