export default function reloadList(
  offset = 0,
  page = 1,
  prefix,
  savedSearch = null,
  rowTotalCount = null,
  previousList = null
) {
  this.load(); // aktvuje načítání
  let list = previousList;
  if (list === null) {
    list = this.dataGet(prefix); // získáme data z aktuálního listview
  }

  const module = list.modul;
  const searchData = this.reloadListParams({list,prefix,savedSearch,offset,page});
  this.setStorage("listViewSearch",{module:module,data:searchData});

  var lastOrder = this.getStorage("lastOrder");
  if(lastOrder == null) {
    lastOrder = {}
  }
  lastOrder[module] = searchData.order
  this.setStorage("lastOrder",lastOrder);

  if (this.getViewName() !== 'import') {
    localStorage.setItem(module+"_saved_search_fail", true);
  }

  let timeout = setTimeout(function(){
    if(localStorage.getItem(module+"_saved_search_fail")){
      localStorage.removeItem(module+"_saved_search");
    }
  }, 15000);

  this.rest.post("list/" + module, searchData, data => {

    clearTimeout(timeout);
    localStorage.removeItem(module+"_saved_search_fail");

    if (typeof data !== "object") {
      this.error("špatný návrat ze serveru");
      this.unLoad();
      return;
    }

    var content = this.listContent(data);
    content.orderBy = searchData.order[0].field;
    content.asc = searchData.order[0].sort === 'ASC' ? 1 : 0;
    if(data["saved_search_id"] !== undefined && data["saved_search_id"] != null){
     savedSearch = data["saved_search_id"];
    }

    const actFiltering = list.actFiltering;
    const defaultFilter = list.defaultFilter && list.defaultFilter.size > 0 ? list.defaultFilter : undefined;
    var akce = {
      type: "RELOADLIST",
      listViewPrefix: prefix,
      view: this.getViewName(),
      page: module,
      filter: list.filter,
      buttons: data.menu,
      defaultFilter: defaultFilter,
      selected: list.selected,
      selectedActive:list.selectedActive,
      fieldFunction: list.fieldFunction,
      rowTotalCount: rowTotalCount,
      actPagination: page,
      savedSearch,
      content,
      actFiltering,
    };

    const view = this.getViewName();
    if (view === 'import') {
      akce.buttons = list.buttons;
    }

    const filterData = {
      filter: list.filter,
      actFiltering: actFiltering,
      actPagination: page,
      offset: content.offset,
      page: module,
      defaultFilter: defaultFilter
    };

    if (list.type !== 'subpanel') {
      let filter = this.getStorage('listFilterData');
      filter = filter === null ? {} : filter;
      //view+module kvuli importu - vyuziva list stejneho modulu kam importujeme, ale ma jine view
      filter[view+module] = filterData;
      this.setStorage('listFilterData', filter);
      savedSearch = savedSearch ?? ' ';
      localStorage.setItem(module+"_saved_search", savedSearch);
    }

    this.dsAddCustom(akce);
    this.dsProcess();

    /*
      Pokud se jedná o uložené vyhledávíní je potřeba provolat funkci afterLoadListView, kvůli tomu aby 
      se správně zobrazovalo listview
    */
    if (savedSearch != null) {
      this.afterLoadListView(prefix);
    }
    // _________________________________________________________________________________________________
  });
}
