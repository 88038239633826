import React from 'react'
import PureComponent from '../../pure'


import  sAction  from "sAction";

class InvCandidateRow extends PureComponent{

    addInvitee(data, type, delInd){
        let relPrefix = this.props.prefix + "/relatedInv/"+type
        let searchPrefix = this.props.prefix + "/searchInv/"+type
        data.email = data.email_address;
        sAction.dsClear();
        sAction.dsAdd("add", relPrefix, data);
        sAction.dsProcess();
        sAction.updateDetailCustom("delItem", {way: searchPrefix, index: delInd});
    }

    render(){

        const data = this.props.data;

        let icon = null;
        const ind = this.props.ind;
        const type = this.props.type;

        switch(type){
            case "Users":
                icon = <div className="icon-avatar calModuleIcon" />
                break;
            case "Contacts":
                icon = <div className="icon-Contacts calModuleIcon" />
                break;
            case "Leads":
                icon = <div className="icon-Leads calModuleIcon" />
                break;
        }        

        return (
            <tr>
                <td>
                    {icon}
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.email_address}
                </td>
                <td>
                    {data.phone_work}
                </td>
                <td>
                    {data.phone_mobile}
                </td>
                <td>
                    <a onClick={() => this.addInvitee(data, type, ind)}><div className="icon-addIcon invAddIcon"></div></a>
                </td>
            </tr>
        )
    }
}
export default InvCandidateRow