import React from "react";
import PureComponent from "../pure";
import ImportFileHeaderSelect from "./ImportFileHeaderSelect";
import sAction from "sAction";

class ImportFileHeader extends PureComponent {
    constructor(props) {
        super(props);
    }

    constructHeader = (data, header) => {
        const rows = data.get('import').toJS();
        const columnCount = data.get('columnCount');
        const selectContents = data.get('fields').get('fields').toJS();
        let options = [];
        let typeMap = {};
        this.populateDropdown(selectContents, options, typeMap);

        for (let i = 0; i < columnCount; i++) {
            header.push(
                <th key={i}>
                    <ImportFileHeaderSelect way={this.props.way + "/mapping"} data={options} typeMap={typeMap} index={i}/>
                </th>
            )
        }
    }

    populateDropdown = (fields, options, typeMap) => {
        options.push(
            { value: null, label: <em className="importMappingTableHeaderSelectOption">{sAction.translate('LBL_IGNORE_FIELD')}</em> }
        )
        for  (const field of fields) {
            const name = sAction.translate(field['vname']);
            typeMap[field['name']] = { type: field['type'], dbType: field['dbType'], name: name }
            options.push(
                { value: field['name'], label: <span className="importMappingTableHeaderSelectOption">{name}</span> }
            )
        }
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        let header = [];
        if (data !== undefined) {
            this.constructHeader(data, header);
        }

        return(
            <tr>
                {header}
            </tr>
        )
    }
}

export default ImportFileHeader;