import React from "react";
import FilterBasic from "./filterBasic";

import MultiSelect from '../../formElements/MultiSelect'

import  sAction  from "sAction";

export default class FilterEnum extends FilterBasic {
  componentDidUpdate() {
    const actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.props.parent.normal();
    } else {
      this.props.parent.advanced();
    }
  }
  multiEnumValue(values){
    const data = this.props.data
    this.addFilter(data.get("name"), "contArray", values)

  }

  render() {
    const data = this.props.data;
    const actFilter = this.props.actFilter
    
    var defaultValue = null;
    if(actFilter != null){
      defaultValue = actFilter.filters.get(0).value;
    }

    const options = sAction.app_strings[data.get("options")];
    var optionsToSelect = [];
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        var value = options[key];
        optionsToSelect.push({ value: key, label: value });
      }
    }


    const custom = [
      <div className="filterEnumSelectContainer" key="select">
        <MultiSelect
        ref="input"
        id={data.get("options")}
        options={optionsToSelect}
        defaultValue={defaultValue}
        open={false}
        valueFromProps={true}
        onBlur={values => this.multiEnumValue(values)}
      />
      </div>
    ];

    var removeFilterButton = null;
    if (actFilter != null) {
      removeFilterButton = (
        <div
          onClick={() => this.removeFilter(data.get("name"))}
          className="filterButton cancelFilterButton"
        >
          {sAction.translate("LBL_DELETE_FILTER")}
        </div>
      );
    }
    return (
      <div>
        <div
          onClick={() => this.order(data.get("name"), 1)}
          className="filterButton"
        >
          {sAction.translate("LBL_ORDER_AZ")}
        </div>
        <div
          onClick={() => this.order(data.get("name"), 0)}
          className="filterButton"
        >
          {sAction.translate("LBL_ORDER_ZA")}
        </div>
        <div
          onClick={() => this.addFilter(data.get("name"), "nnull", null)}
          className="filterButton"
        >
          {sAction.translate("LBL_CONTAINS_DATA")}
        </div>
        <div
          onClick={() => this.addFilter(data.get("name"), "null", null)}
          className="filterButton"
        >
          {sAction.translate("LBL_NOT_CONTAINS_DATA")}
        </div>
        {custom}
        {removeFilterButton}
      </div>
    );
  }
}
