import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";

//material
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//ikony
//import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import HomeTabAddOrEditPopup from "../popup/home/HomeTabAddOrEdit";
import HomeWidgetAddPopup from "../popup/home/HomeWidgetAdd";
import MoreOptions from '../formElements/MoreOptions'
//parent src\components\home\HomeTabs.js
export default class HomeTabsSubmenu extends PureComponent {

    constructor(props){
      super(props)
      this.state={
        open:false
      }
      this.el = React.createRef();
    }

  //otevreni kontext. menu
  handleClick = event => {
    this.setState({ open: true });
  };

  //zavreni kontext. menu
  handleClose = () => {
    this.setState({ open: false });
  };

  handleTabAdd = () => {
    this.handleClose();

    //otevre popup okno na pridani noveho tabu
    sAction.popup(
      <HomeTabAddOrEditPopup name="" value={1} add={true} />
    );
  };

  handleWidgetAdd = () => {
    this.handleClose();

    //otevre popup okno na pridani noveho tabu
    sAction.popup(<HomeWidgetAddPopup />, { header: sAction.translate("LBL_ADD_DASHLETS", "Home") });
  };

  handleCloneDashboard = () => {
    this.handleClose();

    //otevre popup okno na pridani aktualniho pohledu jinym uzivatelum
    sAction.openRolesPopup(sAction.translate("LBL_CLONE_DASHBOARD", "Home"), (data) => {
      sAction.homeSetView(data);
    });
  };

  render() {
    const is_admin = sAction.dataGet("conf/user/is_admin");

    const options = [
      { label: sAction.translate("LBL_ADD_PAGE", "Home"), value: "handleTabAdd" },
      { label: sAction.translate('LBL_ADD_DASHLETS', 'Home'), value: "handleWidgetAdd" },
    ]
    if (is_admin == 1 ) {
      options.push({ label: sAction.translate('LBL_CLONE_DASHBOARD', 'Home'), value: "handleCloneDashboard" })
    }

    return (
      <div className="homeTabsAddSubMenu" ref={this.el}>
        {this.props.item}
        <MoreOptions
            width={200}
            onRight={true}
            fromRight={180}
            onClick={(e, val) => this[val](e)}
            options={options}
          >
            <div className=" icon-More" />
          </MoreOptions>
      </div>
    );
  }
}
