import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";

export default class ListViewPagination extends PureComponent {
  pagination(type) {
    const data = {
      offset: this.props.offset,
      limit: this.props.limit,
      rowCount: this.props.rowCount,
      rowTotalCount: this.props.rowTotalCount,
      page: this.props.page,
      prefix: this.props.prefix,
      type: type
    };
    sAction.listPagination(data);
  }
  render() {
    const module = this.props.module;
    const offset = this.props.offset;
    const rowCount = this.props.rowCount;
    var selected = this.props.selected;
    const selectedActive = this.props.selectedActive;
    const page = this.props.page;
    const filter = this.props.filter;
    const rowTotalCount = this.props.rowTotalCount

    var pagination = (
      <div className="pagination">
        <div
          className="arrow iconPageStart"
          onClick={() => this.pagination("start")}
        />
        <div
          className="arrow iconPageBack"
          onClick={() => this.pagination("back")}
        />
        <div className="pageCount">
          {/*{sAction.translate("LBL_ACTPAGE") + " " + page} */}
          {page}
        </div>
        <div
          className="arrow iconPageNext"
          onClick={() => this.pagination("next")}
        />
        {rowTotalCount != null && 
          <div
          className="arrow iconPageEnd"
          onClick={() => this.pagination("end")}
        />
        }
      </div>
    );

    const type = this.props.type;
    var pomClass = "";
    if (type == "rightPanelQuotes") {
      pomClass = " rightPanelQuotes";
    }
    console.log(rowTotalCount)
    return (
        <div className={"paginationContainer footerBlock" + pomClass}>
          {pagination}
          {(offset+1) + "-"+ (rowCount + offset)}
          {rowTotalCount == null ?
            <div className="icon-listviewCount listviewCount" onClick={() => sAction.listViewCount(this.props.prefix)} 
            title={sAction.translate("LBL_ALL_RECORDS")}
          />
          :
           <div title={sAction.translate("LBL_ALL_RECORDS")}>{"("+rowTotalCount+")"}</div> 
          }  
        </div>
    );
  }
}
