export default function findDuplicates(way, mapping) {
    let duplicateMappings = false;
    for (let i = 0; i < mapping.length; i++) {
        for (let j = i + 1; j < mapping.length; j++) {
            if (mapping[i] === mapping[j] && mapping[i] !== null) {
                this.dsAdd('set', way + '/table/header/mapping/duplicates/' + i, true);
                this.dsAdd('set', way + '/table/header/mapping/duplicates/' + j, true);
                duplicateMappings = true;
            }
        }
    }

    return duplicateMappings;
}