export default function printTimeline(params) {
  const { prefix,all } = params;
  const recordData = this.dataGet("view",["id","module","name"]);
  const storeData = this.dataGet(prefix);
  const data = this.activitiesTimeLineParams(storeData);


  if(all){
    data["limit"] = "";
  }

  data.limit = storeData.records.size;

  this.printFile({
    action: 'printPdf',
    button_id: 'printTimeline',
    id:recordData.id,
    name: recordData.name + " - aktivity",
    module: "Timeline",
    filetype: "pdf",
    relModule:recordData.module,
    params:data,
  });
}
