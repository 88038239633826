export default function reportWindowFindAllRelate(){
    const data = this.dataGet("reportWindow");
    if (data.group != null && data.group.children.size != 0) {
      var relatePole = {};
      relatePole[data.module] = {main:true,module:data.module}
        createFilterFromGroup(data.group,relatePole,"");
        this.dataSet("reportWindow/activeRelate",relatePole);
      } else {
        this.dataSet("reportWindow/activeRelate",[]);
      }
}
function createFilterFromGroup(data,relatePole,relText) {
  if (data.children.size != 0) {
    var state = true;
    data.children.forEach(child => {
      const field = createOperand(child,relatePole,relText);
    });
  }
}
function createOperand(data,relatePole,relText) {
  var operand = null;
  if (data.type == "group") {
    operand = createFilterFromGroup(data,relatePole,relText);
  } else if (data.type == "relate") {
    operand = createFilterFromRelate(data,relatePole,relText);
  } else {
    return false;
  }
  return operand;
}
function createFilterFromRelate(data,relatePole,relText) {
  
  var relName = data.def.get("link");
  if(relName != undefined && relName != ""){
    if(relText == ""){
      relText = relName
    }else{
      relText += "/*"+relName
    }
  }

  if (data.group != null) {
    relatePole[relText] = {
      module: data.module,
      type: "relate",
      name: data.def.get("name"),
      relationship:relText.split("/*"),
    };
    createFilterFromGroup(data.group,relatePole,relText)
  } 
}