import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import Select from "../formElements/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ImportFileHeaderSelect extends PureComponent {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        sAction.dsClear();
        sAction.dsAdd('set', this.props.way + '/' + this.props.index, e.target.value);
        sAction.dsProcess();
        this.boolSelectedInfo(e.target.value);
    }

    boolSelectedInfo = (selectedVal) => {
        const typeMap = this.props.typeMap;

        if (typeMap.hasOwnProperty(selectedVal) && (typeMap[selectedVal].type === 'bool' || typeMap[selectedVal].dbType === 'bool')) {
            const optionName = typeMap[selectedVal].name;
            sAction.alert(optionName + " je pole typu Bool, které může obsahovat pouze hodnoty 0 a 1");
        }
    }

    componentDidMount() {
        sAction.dsClear();
        sAction.dsAdd('set', this.props.way + '/' + this.props.index, null);
        sAction.dsProcess();
    }

    componentDidUpdate(prevProps, prevState) {
        const prefix = this.props.way;
        const index = this.props.index;
        const mapping = sAction.dataGet(prefix + '/' + index);
        if (mapping === undefined) {
            sAction.dsClear();
            sAction.dsAdd('set', prefix + '/' + index, null);
            sAction.dsProcess();
        }
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        const index = this.props.index;
        const mapping = sAction.dataGet(prefix + '/' + index);
        const duplicateSelect = sAction.dataGet(prefix + '/duplicates/' + index);
        let error;
        let noVal = null;

        if(duplicateSelect) {
            error = true;
        }

        if (mapping === null) {
            noVal = '';
        }

        return(
            <div className="importMappingTableHeaderSelect">
                <Select options={data} value={noVal}  autoWidth={true}
                        menuProps={{
                            style: {maxHeight: "500px"},
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            },
                            getContentAnchorEl: null
                        }}
                        error={error} onChange={this.onChange} label={sAction.translate('LBL_CHOOSE_MAPPING')}>
                </Select>
            </div>
        )
    }
}

export default ImportFileHeaderSelect;