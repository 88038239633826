export default function dataGet(way, values = null, toJS = false) {
  if (way.indexOf("/") == -1) {
    // rozparsovaní cesty do pole
    way = [way];
  } else {
    while (way.indexOf("/..") != -1) {
      way = way.replace(/\/[a-zA-z0-9\-\_]+\/[.][.]/, "");
    }
    way = way.split("/");
  }

  const app = this.store.getState().appReducer;
  const data = app.getIn(way);

  
  if (values != null) {
    var arrayData = {};
    values.forEach(val => {
      arrayData[val] = data.get(val);
    });
    return arrayData;
  }else{
    if (toJS == true) {
      return data.toJS();
    } else {
      return data;
    }
  }
}
