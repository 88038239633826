import React, { Component } from 'react'
import PureComponent from '../../pure'

import Button from "../../formElements/Button";
import LeadConvertModule from "./LeadConvertModule";

import  sAction  from "sAction";

class LeadConvertContainer extends PureComponent {

    show(e,data){
        e.stopPropagation();
        sAction.rightPanelDetail(data.module,data.record);
    }
    
    handleSave(prefix){
        sAction.convertLeadSave(prefix);
    }

    handleCancel(rec){
        sAction.href("#detail/Leads/"+rec);       
    }

    render(){
        const way = this.props.way;
        const action = this.props.data.action;
        var renderComp = [];

        if(action == "displayResults"){
            const newBeans = this.props.data.newBeans;
            newBeans.forEach(bean => {
                renderComp.push(
                    <div className="leadConvertNewRecord" >
                        <div className="lcNewRecModLbl">{sAction.translate("LBL_MODULE_NAME", bean.module)}</div>:
                        <div
                            onClick={e => this.show(e, bean)}
                            className="icon-eye detailViewFieldIconEye"
                        />
                        <a target='_blank' key={bean.record} href={'/#detail/'+bean.module+'/'+bean.record}>{bean.name}</a>
                    </div>
                );
            });        

            return <div className="LeadConvertContainer">
                <div className="leadConvertResultsContainer">
                    <div className="LeadConvertModule">
                        <div className="detailCard">
                            <div className="header">{sAction.translate("LBL_CONVERT_NEW_RECORDS", "Leads")}</div>
                            <div className="LeadConvertResults">
                                {renderComp}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        const views = this.props.data.detailViews;
        const record = this.props.data.record;

        views.forEach((view, ind) => {
            if(view.module == null){
                return;
            }
            let prefix = way+"/detailViews/"+ind;
            renderComp.push(
               <LeadConvertModule 
                data={view} 
                key={view.module} 
                prefix={prefix} 
                way={prefix + "/tabs/0"} 
                index={ind} 
                />
            );
        });

        
        return <div className="LeadConvertContainer">
        <div className="viewActionPanel leadConvertPanel">
            <div className="viewActionPanelButtons">
                <Button className="hoverGreen acmButton" onClick={() => {this.handleSave(way)}}><div className="icon-saveIcon actionPanelButtonIcon" />{sAction.translate("LBL_SAVE_RECORD")}</Button>
                <div className="viewPanelButtonsDelimiter" />
                <Button className="acmButton" onClick={() => {this.handleCancel(record)}}><div className="icon-deleteIcon actionPanelButtonIcon" />{sAction.translate("LBL_STORNO")}</Button>
            </div>
        </div>
            <div className="LeadConvertModulesContainer">
                {renderComp}
            </div>
        </div>
    }
}
export default LeadConvertContainer