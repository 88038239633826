export default function currencyConvert(val, oldCur, newCur){
    //console.log("currencyConvert", this.currencies);

    if(oldCur == newCur || oldCur == undefined || newCur == undefined){
        return val;
    }
    
    
    if(oldCur == "-99"){
        const conv = this.currencies[newCur].conversion_rate;
        return val * conv;
    }
    
    if(newCur == "-99"){
        const conv = this.currencies[oldCur].conversion_rate;
        return val / conv;
    }

    const oldConv = this.currencies[oldCur].conversion_rate;
    const newConv = this.currencies[newCur].conversion_rate;

    return (val / oldConv) * newConv;

}