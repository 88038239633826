import React from "react";

import DropZone from "../../DropZone/DropZone";
import Button from "../../formElements/Button";
import  sAction  from "sAction";

export default class Files extends React.PureComponent {
  state = {
    hidden: true,
    uploading: {
      percent: 100,
      loaded: 0,
      total: 0
    }
  };

  componentDidMount() {
    sAction.dataSet(this.props.changesWay, {});
  }

  addAttachment = () => {
    if (this.state.hidden) {
      this.setState({ hidden: false });
      //return;
    }

    document.querySelector("input[type=file]").click();
  };

  update = files => {
    const data = {
      data: {
        cache: true,
        note: files
      },
      action: "createNote",
      responseType: "json",
      success: response => {
        //prilohy se ulozi do storu
        sAction.dsClear();
        response.forEach(f => {
          sAction.dsAdd("set", this.props.way + "/" + f.id, f);
          sAction.dsAdd("set", this.props.changesWay + "/" + f.id, f);
        });
        sAction.dsProcess();
      },
      progress: progress => {
        let { loaded, total, percent } = progress;
        percent = Math.round((Math.ceil(percent) || 0) / 4) * 4;
        if (percent !== this.state.uploading.percent)
          this.setState({ uploading: { percent, loaded, total } });
      }
    }

    sAction.filesXhr(data);
  };

  delete = (i, deleteAll = false) => {
    if (!this.props.data.def.get("files")) return;

    const a = this.props.data.def.getIn(["files", i]);

    if (!deleteAll) sAction.dsClear();
    sAction.dsAdd("delete", this.props.way, i);

    // uzivatel maze prave nahravane soubory - smazou se ze serveru a storu
    if (a.get("source") !== "sugar" && a.get("source") !== "sugartemplate") {
      sAction.rest.post("email/removeUploadedAttachment", {
        file: a.get("id") + a.get("name")
      });
      sAction.dsAdd("delete", this.props.changesWay, i);
    } else {
      // uzivatel maze jiz existujici soubory - nastaveni se jen priznak deleted = true
      const data = a.merge({ deleted: true });
      sAction.dsAdd("set", this.props.changesWay + "/" + i, data);
    }

    if (!deleteAll) sAction.dsProcess();
  };

  deleteAll = e => {
    e.preventDefault();
    e.stopPropagation();

    sAction.confrim(
      sAction.translate("LBL_EMAIL_UPLOAD_DELETE_ALL_CONFIRM", "Emails"),
      () => {
        sAction.popupHide();

        sAction.dsClear();
        this.props.data.def
          .get("files")
          .keySeq()
          .forEach(id => {
            this.delete(id, true);
          });
        sAction.dsProcess();
      }
    );
  };

  addSugarDocument = e => {
    e.stopPropagation();
    e.preventDefault();

    if (this.state.hidden) {
      this.setState({ hidden: false });
      //return;
    }

    const paramData = {
      module: "Documents"
    };
    sAction.openRelatePopup(paramData, returnData => {
      returnData.type = "";
      returnData.size = "";
      returnData.source = "sugar";

      sAction.popupHide();
      sAction.dsClear();
      sAction.dsAdd("set", this.props.way + "/" + returnData.id, returnData);
      sAction.dsAdd("set", this.props.changesWay + "/" + returnData.id, returnData);
      sAction.dsProcess();
    });
  };

  downloadFile = e => {
    e.stopPropagation();
    e.preventDefault();
    const attachment = this.props.data.def.getIn(["files", e.target.dataset.i])
    const id = attachment.get("id");
    const name = attachment.get("name");
    sAction.downloadFile({
      id,
      name,
      cache: true,
      preventDelete: true,
    });
  };

  render() {
    const { hidden, uploading } = this.state;

    return (
      <div style={{width: "100%"}}>
        <DropZone
          attachments={this.props.data.def.get("files")}
          hidden={hidden}
          uploading={uploading}
          addSugarDocument={this.addSugarDocument}
          delete={this.delete}
          deleteAll={this.deleteAll}
          update={this.update}
          handleDownload={this.downloadFile}
          readonly={this.props.readonly}
        />

        {!this.props.readonly && (
        <div className="viewActionPanelButtons">
          <Button onClick={this.addAttachment} className="hoverBlue">
            <div className="actionPanelButtonIcon icon-attachment" />{" "}
            {/*Přidat přílohu z PC*/}
            {/*Přidat soubor*/}
            {sAction.translate("LBL_ADD_FILE", "Emails")}
          </Button>

          <div className="viewPanelButtonsDelimiter" />

          <Button onClick={this.addSugarDocument} className="hoverBlue">
            <div className="actionPanelButtonIcon icon-attachment2" />{" "}
            {/*Přidat Sugar Dokument*/}
            {sAction.translate("LBL_ADD_DOCUMENT", "Emails")}
          </Button>
        </div>)}
      </div>
    );
  }
}
