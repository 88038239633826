import React from "react";
import MaterialDefault from './MaterialDefault'
import MaterialRadio from '@material-ui/core/Radio';

export default class Radio extends MaterialDefault {
    constructor(props){
        super(props)

    }
    onChange(e){
        this.setState({
            value: e.target.value
        })
        if(this.props.onChange != undefined){
            this.props.onChange(e);
        }
    }
    render(){

        const attr = this.createAttr("acmRadio");

        return(
            <MaterialRadio 
            tabIndex="0"
            {... attr}
             />
        )
        }
}