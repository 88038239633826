import React from "react";
import PureComponent from "../pure";

export default class Tag extends PureComponent {
  deleteTag(tag, key, way, e) {
    this.props.onRemove(tag, key, way);
    e.stopPropagation(); // prevent calling parent onClick on chrome, firefox & safari
    e.cancelBubble = true // prevent calling parent onClick on IE

    return;
  }
  onClick(e, tag){
    if(this.props.onClick != undefined){
        this.props.onClick(e, tag);
    }
  }

  render() {
    const tag = this.props.tag;
    const way = this.props.way;
    const key = this.props.keyId;
    const colored = this.props.colored || false;
    const removable = this.props.removable;

    var className = tag.get("type") ? "tag" + tag.get("type") + " tag" : "tag";
    colored ? className += ' colored': null;
    this.props.onClick ? className += ' clickable' : null;

    return (
      <span
        className={className}
        key={tag.get("id")}
        onClick={(e) => this.onClick(e, tag)}
      >
        <div className="tagText">
          {tag.get("name")}
          {removable == true && (
            <span
            className="icon-calCloseIcon"
            onClick={(e) => this.deleteTag(tag, key, way, e)}
          />
          )}
        </div>
      </span>
    );
  }
}
