export default function searchInvitees(params, prefix){
    var self = this;

    if(params.parent){
        const def = params.parent.def;
        if(!def.parent_type_value || !def.id_value){
            return;
        }
    }
    else if(!params.first_name && !params.last_name && !params.email_address){
        //TODO vyprazdni search invitees list
        return;
    }

    this.rest.post("searchInvitees", params,
        function(invitees) {

            const content = {
                invitees : invitees
            }

            const action = {
            type : "CALENDARSEARCHINVITEES",
            content: content,
            prefix: prefix
            }

            self.dsClear();
            self.dsAddCustom(action);
            self.dsProcess();

    });
}