export default function reportWindowColumnsModule(value, dsProcess = true) {
  const activeRelate = this.dataGet("reportWindow/activeRelate");
  const modul = activeRelate.getIn([value, "module"]);
  const main = activeRelate.getIn([value, "main"]);
  const data = {
    limit: 1,
    offset: 0,

    order: [
      {
        module: modul,
        field: "date_entered",
        sort: "asc"
      }
    ],
    filter: {}
  };
  if (dsProcess) {
    this.dsClear();
  }
  this.dsAdd("set", "reportWindow/columns/hidden", []);
  var self = this;



  this.rest.post("list/" + modul, data, data => {
     this.reportWindowColumnsModuleSet(data,main,value);
  });
}
