import React from 'react'
import PureComponent from '../../pure'


import  sAction  from "sAction";

class InvRow extends PureComponent{

    removeInvitee(type, ind){
        let prefix = this.props.prefix + "/relatedInv/"+type;
        console.log("REM", prefix);
        if(prefix.indexOf("view") === -1){
            sAction.dataDelete(prefix, ind);
        }
        else{
            sAction.updateDetailCustom("delItem", {way: prefix, index: ind});
        }
    }

    render(){
        
        const data = this.props.data;
        let icon = null;
        const ind = this.props.ind;
        const type = this.props.type;

        switch(type){
            case "Users":
                icon = <div className="icon-avatar calModuleIcon" />
                break;
            case "Contacts":
                icon = <div className="icon-Contacts calModuleIcon" />
                break;
            case "Leads":
                icon = <div className="icon-Leads calModuleIcon" />
                break;
        }

        return (
            <tr>
                <td>
                    {icon}
                </td>
                <td>
                    {data.name}
                </td>
                <td>
                    {data.email}
                </td>
                <td>
                    {data.phone_work}
                    {data.phone_work != "" &&
                    <a className="invitesTableCallTo" href={"callto:"+data.phone_work} tabIndex="0" title="LBL_CALL_TO"><div style={{display:"inline"}} className="icon-Calls"></div></a>
                    }
                </td>
                <td>
                    {data.phone_mobile}
                    {data.phone_mobile != "" &&
                    <a className="invitesTableCallTo" href={"callto:"+data.phone_mobile} tabIndex="0" title="LBL_CALL_TO"><div style={{display:"inline"}} className="icon-Calls"></div></a>
                    }
                </td>
                <td>
                <a onClick={() => this.removeInvitee(type, ind)} className="calDeleteIcon"><div className="icon-deleteIcon"></div></a>
                </td>
            </tr>
        )
    }
}
export default InvRow