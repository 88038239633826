import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import MenuButton from "./menuButton";

export default class MenuGroup extends PureComponent {
  render() {
    var colorNumber = 0;
    const data = this.props.data;
    const groupIndex = this.props.groupIndex;
    const open = this.props.open;
	const colorCount = sAction.colorTemplate.length;
    const buttonsRender = data.buttons.map((button, buttonIndex) => {
      const iconColor = sAction.colorTemplate[colorNumber];
      if (colorNumber >= colorCount) {
        colorNumber = 0;
      } else {
        colorNumber += 1;
      }
      return (
        <MenuButton
          key={buttonIndex}
          iconColor={iconColor}
          index={buttonIndex}
          activeModule={this.props.activeModule}
          way={"menu/groups/" + groupIndex + "/buttons/" + buttonIndex}
          ref={button.name}
          button={button}
        />
      );
    });

    return (
      <div>
        {this.props.oneGroup || (
          <div
            onClick={() => sAction.toggleMenuGroup(groupIndex)}
            className={"menuGroup" + (open ? " open" : "")}
          >
            <span className={"icon-" + data.icon} />
            {sAction.translate(data.name)}
          </div>
        )}
        <div className={"menuGroupItems" + (open ? " fullHeight" : "")}>
          {buttonsRender}
        </div>
      </div>
    );
  }
}
