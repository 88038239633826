export default function emailChangeSignature(signature, props) {
  if (!signature) {
    this.dataSet(props.way + "/loaded", true);
    return;
  }

  signature = "<signature><p><br></p>" + signature + "</signature>";

  let contents = this.dataGet(props.way + "/description_html");
  if (contents == undefined) return;

  const original_message_title = this.translate(
    "LBL_EMAIL_ORIGINAL_MESSAGE",
    "Emails"
  );

  //pokud uz nejaky podpis byl pridan, nahradi se
  if (contents.match(/<signature>/)) {
    const regexp1 = /(<p><br( \/)?><\/p>)?\s*<p><signature>.*<\/signature><\/p>/g;
    const regexp2 = /<signature>.*<\/signature>/;

    if (regexp1.test(contents)) {
      contents = contents.replace(regexp1, signature);
    } else if (regexp2.test(contents)) {
      contents = contents.replace(regexp2, signature);
    }

    //pokud ne, ale obsahuje citovanou zpravu, prida se pred ni
  } else if (
    contents.match(new RegExp("<p>" + original_message_title + "</p>"))
  ) {
    contents = contents.replace(
      new RegExp(
        "(<p><br( /)?></p>)?\\s*(<p>" + original_message_title + "</p>)",
        "g"
      ),
      signature + "<p><br /></p>$3"
    );

    //jinak se prida na konec
  } else {
    contents += signature;
  }

  this.dsClear();
  this.dsAdd("set", props.way + "/description_html", this.htmlDecode(contents));
  this.dsAdd("set", props.way + "/updateEmailText", true);
  this.dsAdd("set", props.way + "/loaded", true);

  if (props.data.signature !== signature) {
    this.dsAdd("set", props.way + "/signature", signature);
  }

  this.dsProcess();
}
