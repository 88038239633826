
export default function translate(key,module){
  var preklad;

  if(module == undefined){
    module = this.getModuleName();
  }

    try{
       preklad = this.moduleTranslate[module][key];
    }catch(e){
       console.log("chybí překlad "+key+" v překladech modulu "+module);
       preklad = undefined;
    }
    
    if(preklad != "" && preklad != undefined){
      return preklad;
    }else{
      const another = this.app_strings[key];
      if(another != "" && another != undefined){
        return another
      }else{
      return key;
      }
    }
}
