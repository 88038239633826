import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import ImportFileCell from "./ImportFileCell";

class ImportFileRow extends PureComponent {
    constructor(props) {
        super(props);
    }

    createCells = (data, cells, count) => {
        for (let i = 0; i < count; i++) {
            const field = data.get(i) !== undefined ? data.get(i) : null;
            cells.push(
                <ImportFileCell key={i} data={field} />
            );
        }
    }

    render() {
        const data = this.props.data;
        let cells = [];

        if (data !== undefined) {
           this.createCells(data, cells, this.props.columnCount);
        }

        return(
            <tr>
                {cells}
            </tr>)
    }
}

export default ImportFileRow;