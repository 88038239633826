import React from "react";
import PureComponent from "../pure";
import ImportFileHeader from "./ImportFileHeader";
import ImportFileRow from "./ImportFileRow";

class ImportFileTable extends PureComponent {
    constructor(props) {
        super(props);
    }

    createRows = (data, rows, columnCount) => {
        let i = 0;
        for (i; i < data.size; i++) {
            const rowData = data.get(i.toString());
            rows.push(
                <ImportFileRow key={i} data={rowData} columnCount={columnCount}/>
            )
        }
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        let header;
        let rows = [];
        if (data !== undefined)
        {
            header =  <ImportFileHeader way={prefix + "/header"} data={data} />;
            this.createRows(data.get('import'), rows, data.get('columnCount'));
        }

        return(
            <div className="importTableWrapper">
                <table className="importMappingTable">
                    <thead className="importMappingTableHeader">
                    {header}
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default ImportFileTable;