export default function processFileInput(paramData,callback) {
    var tempEl = document.createElement("input");
    tempEl.setAttribute("type", "file");
    if (paramData.multiple) {
      tempEl.setAttribute("multiple", paramData.multiple)
    }
    tempEl.style = "display: none";
    document.body.appendChild(tempEl);
    tempEl.addEventListener("change", e => {
      var files = [];
      tempEl.files.forEachObject((file, key) => {
        if (key != "length") {
          files.push(file)
        }
      });
      tempEl.parentNode.removeChild(tempEl);
      if(callback != undefined){
        callback(files)
      }else{
        this.uploadFiles(files,paramData.prefix)
      }
    });
    tempEl.click();
}