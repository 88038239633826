
import React, { Component } from 'react'

import InputField from '../inputFieldClass'

export default class Int extends InputField {
  
  constructor(){
    super();
    this.state = {
      state: "fine",
      reg: /^[0-9]+$/,
    };
  }
}
