export default function reportWindowColumnsModuleSet(data,main,value){
    const modul = data.module
    const activeColumns = this.dataGet("reportWindow/columns/active");
    var activeColumnsArray = [];
    activeColumns.forEach(column => {
      if (column.def != undefined) {
        activeColumnsArray.push(
          column.def.get("fieldModule") + "/" + column.name
        );
      }
    });

    data.rows.forEachObject((row, key) => {
        const k = key.toLowerCase();
        const nameForCompare = modul + "/" + k;
        if (activeColumnsArray.indexOf(nameForCompare) == -1) {
          var def = data.def[k];
          if (def != undefined) {
            def["width"] = row.width;
            def["default"] = row.default;
            def["show"] = true;
            def["fieldModule"] = modul;
            if (main != true) {
              def["relationship"] = value;
            }
            this.dsAdd(
              "add",
              "reportWindow/columns/hidden",
              { name: k, def: def },
              "reportWindowColumn"
            );
          }
        }
      });
      this.dsProcess();
}