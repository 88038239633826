export default function clearRelatedDynamicEnums(way,id){
        var fields = this.dataGet(way+"/fields");
        fields.forEach(field => {
            const type = field.def.get("type");
            if(type == "AcmDynamicEnum"){
                const parent = field.def.getIn(["relData","parent"]);
                if(parent != undefined && parent == id){
                    this.dsAdd("set",way+"/fields/"+field.name+"/value","");
                    this.dsAdd("set",way+"/changes/fields/"+field.name,"");
                    const fieldOption = field.def.get("options");
                    this.clearRelatedDynamicEnums(way,fieldOption);
                }
            }
        });
        this.dsProcess();
}