import React, { Component } from 'react'
import PureComponent from '../../pure'

import InvRow from './invRow';
import InvCandidateRow from './invCandidateRow';

import  sAction  from "sAction";

class InviteesContainer extends PureComponent{

    constructor(){
        super();

        this.fname = React.createRef();
        this.lname = React.createRef();
        this.email = React.createRef();
    }

    searchInv(){

        const params = {
            first_name:this.fname.current.value,
            last_name:this.lname.current.value,
            email_address:this.email.current.value     
        }

        sAction.searchInvitees(params, this.props.prefix);
    }

    render(){
        
        const data = this.props.data.relatedInv;
        const searchInv = this.props.data.searchInv;
        const prefix = this.props.prefix;
        const pomClass = this.props.pomClass;

        let invArr = [];
        
        let ind = 0;
        data.Users.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        ind = 0;
        data.Contacts.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        ind = 0;
        data.Leads.forEach(elem => {
            let row = <InvRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix}/>;
            invArr.push(row);
        });

        let searchRes = [];

        ind = 0;
        searchInv.Users.forEach(elem => {
           searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Users" ind={ind++} prefix={prefix}/>); 
        });

        ind = 0;
        searchInv.Contacts.forEach(elem => {
            searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Contacts" ind={ind++} prefix={prefix}/>); 
         });
         
         ind = 0;
         searchInv.Leads.forEach(elem => {
            searchRes.push(<InvCandidateRow key={elem.id} data={elem} type="Leads" ind={ind++} prefix={prefix}/>); 
         });

        return (
            <div className={"pageContainer calInvComponent "+pomClass}>
                <div className="detailViewContainer">
                    <div className="detailcard">
                        <div className="detailView">
                            <div className="detailViewTabContent">
                                <div className="relInvContainer">
                                <table className="invTable">
                                    <thead>
                                        <tr><th></th><th>{sAction.translate("LBL_NAME", "Contacts")}</th><th>{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</th><th>{sAction.translate("LBL_PHONE_WORK", "Contacts")}</th><th>{sAction.translate("LBL_PHONE_MOBILE", "Contacts")}</th><th></th></tr>
                                    </thead>
                                    <tbody>
                                        {invArr}
                                    </tbody>
                                </table>
                                </div>
                                <div className="invSearchContainer">
                                <table>
                                    <tbody>
                                        <tr><td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_FIRST_NAME", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.fname} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_LAST_NAME", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.lname} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <div className="invSearchInputContainer">
                                                <span className="invSearchInputLabel">{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</span>
                                                <input className="invSearchInput" ref={this.email} type='text' onChange={() => this.searchInv()} />
                                            </div>
                                            </td>
                                            <td>
                                            <a onClick={() => this.searchInv()}><div className="icon-refresh calRefreshIcon"></div></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div className="invCandidatesContainer">
                                <table className="invTable">
                                    <thead>
                                        <tr><th></th><th>{sAction.translate("LBL_NAME", "Contacts")}</th><th>{sAction.translate("LBL_EMAIL_ADDRESS", "Contacts")}</th><th></th><th></th><th></th></tr>
                                    </thead>
                                    <tbody>
                                    {searchRes}
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default InviteesContainer