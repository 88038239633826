import React from "react";
import PureComponent from "../pure";

import Button from "../formElements/Button";
import ViewPanelMenuOthers from './viewPanelMenuOthers'

import  sAction  from "sAction";

class ViewPanelMenu extends PureComponent {
  constructor(props){
    super(props)
    this.state={
      buttonLimit:-1
    }
    this.panel = React.createRef();
  }
  componentDidMount(){
    //prepocitani limitu poctu tlacitek, kolik se jich vleze na sirku obrazovky
    var panelWidth = this.panel.current.clientWidth
   // console.log(panelWidth);
    panelWidth = panelWidth -160;
   // console.log(panelWidth);
    var buttons = this.panel.current.querySelectorAll(".acmButton");
    var buttonsWidth = 0;
    var doUpdate = true;
    buttons.forEach((button,index) => {
      const buttonWidth = button.clientWidth;
    //  console.log("button - "+buttonWidth);
      buttonsWidth += (buttonWidth+10)
    //  console.log(buttonsWidth)
      if(buttonsWidth > panelWidth && doUpdate == true){
    //    console.log("ted");
        this.setState({
          buttonLimit:index
        })
        doUpdate = false;
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    //kdyz se napr. meni stav nabidky a pribude hodne novych tlacitek, tak je treba prepocitat limit tlacitek
    //aby se nezobrazovaly mimo obrazovku
    this.componentDidMount();
  }

  onClick(action,buttonId, params) {
    const prefix = this.props.prefix;
    const data = this.props.data;
    console.log(params);
    var paramData = {
      prefix: prefix,
      module: data.get('module') == undefined ? data.get('modul') : data.get('module'),
      id: data.id,
      type: data.type,
      name: data.name,
      buttonId:buttonId,
    };
    if (action != undefined && sAction[action] != undefined) {
      if (params != undefined) {
        params.forEach(param => {
          if (param.get("type") == "store") {
            paramData[param.get("name")] = data.get(param.get("value"));
          } else if (param.get("type") == "selector") {
            const element = document.querySelector(param.get("value"));
            console.log(element);
            const value = element.value;
            paramData[param.get("name")] = value;
          } else {
            paramData[param.get("name")] = param.get("value");
          }
        });
      }
      sAction[action](paramData);
    } else {
      console.log('akce "' + action + '" není definována');
    }
  }
  render() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    var renderButtons = [];
    var renderButtonsOthers = [];
    if (sAction.getViewName() == "detail" && data.type != "popup") {
      if (
        (data.changes.get("fields").size > 0 || 
          (data.changes.get("customData") && data.changes.get("customData").size > 0) ||
          data.changes.get("forceChange")) &&
        data.id != null
      ) {
        renderButtons.push(
          <Button
            onClick={() => sAction.removeChangesOnRecord(prefix)}
            key={"returnChanges"}
          >
            <React.Fragment>
              <div className={"actionPanelButtonIcon " + "icon-undo"} />
              {sAction.app_strings["LBL_RETURN_CHANGES"]}
            </React.Fragment>
          </Button>
        );
      }
    }
    data.get('buttons').forEach((button,index) => {

      const buttonAcl = button.def.get("acl") == undefined ? "edit" : button.def.get("acl");
      const acl = data.acl
      if(acl != undefined && acl.get(buttonAcl) == false){
        return;
      }else if(acl == undefined){
        if(sAction.hasAccess(data.module == undefined ? data.modul : data.module,buttonAcl) == false){
          return;
        }
      }

      var buttonClass = "";
      if (button.def.get("extraClass") != undefined) {
        buttonClass += " " + button.def.get("extraClass");
      }
      var actionName = button.def.get("onClick");

      var params = button.def.get("params");
      if(params == undefined){
        params = [];
      }
      var disabled = false;
      if (
        data.selected !== undefined &&
        data.selected.size == 0 &&
        button.def.get("multiple") == true
      ) {
        disabled = true;
      }

      if (
        data.changes !== undefined &&
        (data.changes.get("fields").size > 0 || 
          (data.changes.get("customData") && data.changes.get("customData").size > 0) ||
          data.changes.get("forceChange")) &&
        data.id != null &&
        button.def.get("savedRecord") === true
      ) {
        disabled = true;
      }

      var add = true;
      if (
        button.def.get("newRecord") === false &&
        (data.id == "" || data.id == null)
      ) {
        add = false;
      }
      if (add == true) {
        const buttonRender = (
          <Button
            onClick={() => this.onClick(actionName,button.id, params)}
            className={buttonClass}
            key={button.id}
            id={button.id}
            disabled={disabled}
          >
            {button.def.get("iconClass") != undefined && (
              <div
                className={
                  "actionPanelButtonIcon " +
                  "icon-" +
                  button.def.get("iconClass")
                }
              />
            )}
            {sAction.translate(button.def.get("label"))}
          </Button>
        );
        if (button.def.get("others") == true) {
          renderButtonsOthers.push(buttonRender);
        } else {

          if(this.state.buttonLimit == -1 || index < this.state.buttonLimit){
          renderButtons.push(buttonRender);
          renderButtons.push(
            <div
              className="viewPanelButtonsDelimiter"
              key={button.id + "_delimiter"}
            />
          );
          }else{
            renderButtonsOthers.push(buttonRender);
          }
        }
      }
    });

    // _______________________________________________ stránkování mezi záznamy
    var beforeNext = null;
    if (data.id != "") {
      const lastSearch = sAction.getStorage("listViewSearch");
      if (lastSearch && lastSearch.module == data.module) {
        const number =
          lastSearch.data.offset +
          (sAction.getStorage("listViewSearchIndex") || 0) +
          1;
        beforeNext = (
          <div className="detailPagination">
            {number > 1 && (
              <div
                className="arrow iconPageBack"
                onClick={() => sAction.changeRecord("back", prefix, data.type)}
              />
            )}
            {number}
            <div
              className="arrow iconPageNext"
              onClick={() => sAction.changeRecord("next", prefix, data.type)}
            />
          </div>
        );
      }
    }
 //   console.log(renderButtonsOthers);
    return (
      <div className="viewActionPanel" ref={this.panel}>
        <div className="viewActionPanelButtons">
          {sAction.isMobile()  && sAction.getViewName() == "detail" &&
          <React.Fragment>
          <div className='icon-undo actionPanelButtonIcon' onClick={() => {window.history.go(-1); return false;}} />
          <div className="viewPanelButtonsDelimiter"></div>
          </React.Fragment>
     
          }
          {renderButtons}
          {renderButtonsOthers.length !== 0 && 
            <ViewPanelMenuOthers buttons={renderButtonsOthers} buttonLimit={this.state.buttonLimit} />
          }
        </div>
        {beforeNext}
      </div>
    );
  }
}
export default ViewPanelMenu;
