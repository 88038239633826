import React from "react";
import PureComponent from "../../pure";
import AdminIframe from "../../admin/AdminIframe";
import sAction from "sAction";

export default class ACLRoles extends PureComponent {
  _isAdmin = sAction.dataGet("conf/user/is_admin") == 1;

  setIframeHeight = () => {
    const iframe = document.getElementsByTagName("iframe")[0];
    if (!iframe) {
      return;
    }

    if (iframe.contentWindow) {
      let height;
      try {
        height = iframe.contentWindow.document.body.scrollHeight;
      } catch (e) {
        height = 0;
      }
      var clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

      if (!height || (height > clientHeight)) {
        height = clientHeight - 400;
        if (height<500) height = 500;
      }
      height = clientHeight - 400;
      if (height<500) height = 500;
      document.getElementById("reportContainer").style.height = height + "px";
    }
  };

  componentDidMount() {
    if (this._isAdmin) {
      if (this.props.data.get("html")) {
        setTimeout(this.setIframeHeight, 250);
        setTimeout(this.setIframeHeight, 2500);
        setTimeout(this.setIframeHeight, 5000);
        setTimeout(this.setIframeHeight, 10000);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.componentDidMount();
  }

  render() {
    let { way, data, isInTab } = this.props;

    if (!this._isAdmin) {
      return "";
    }

    data = data.set("params", { recordId: data.get("recordId") });
    let render = <div id="reportContainer" className="detailIframeContainer">
      <AdminIframe way={way.replace("/customData","")} name={data.get("iframeName")} data={data} />
    </div>;

    if (!isInTab) {
      render = <div className="detailCard OppRowsContainer">
        <div className="header">
          <div className="title">
            <div className="detailViewName">
              {sAction.translate("LBL_USER_ACCESS", "Users")}
            </div>
          </div>
        </div>
        <div style={{ padding: 25 }}>
          {render}
        </div>
      </div>;
    }

    return render;
  }
}