import React from "react";
import PureComponent from "../../pure";

import Select from "../../formElements/Select";

import ReportWindowGroup from "./ReportWindowGroup";

import  sAction  from "sAction";

class ReportWindowContent extends PureComponent {
  render() {
    var data = this.props.data;
    var way = "reportWindow";
    var optionsRender = [];
    data.modules.forEach((element, index) => {
      if (element != "Calendar" && element != "Home") {
        optionsRender.push({
          value: element,
          label: sAction.app_strings.moduleList[element]
        });
      }
    });

    var saveSearchOptions = [{ value: "", label: "" }];
    data.savedSearch.forEach(search => {
      saveSearchOptions.push({
        value: search.get("id"),
        label: search.get("name")
      });
    });
    return (
      <div className="reportWindowContent">
        <div className="reportContentLine">
          <div className="reportContentLineCell" style={{ width: "150px" }}>
            <label>{sAction.translate("LBL_REPORT_SEARCH")}</label>
          </div>
          <div className="reportContentLineCell" style={{ width: "300px" }}>
            <Select
              containerClassName="withBorder"
              options={optionsRender}
              defaultValue={data.module}
              onChange={e => sAction.reportSelectMainModule(e.target.value)}
            />
          </div>
          {data.module != null && (
            <div className="reportContentLineCell ">
              <div className="flexRight">{sAction.translate("LBL_REPORT_SAVED_FILTERS")}</div>
            </div>
          )}
          {data.module != null && (
            <div className="reportContentLineCell">
              <Select
                defaultValue={data.metadata != null ? data.metadata.id : ""}
                containerClassName="withBorder"
                options={saveSearchOptions}
                onChange={e => {
                  sAction.load();
                  sAction.reportWindowLoadSearch(data.module, e.target.value)
                }}
              />
            </div>
          )}
          <div className="reportContentLineCell" />
        </div>
        <div className="reportWindowContentPlace">
          <div
            className={
              data.filterAlert.get("alert") === true
                ? "reportWindowFilterMessage"
                : "reportWindowFilterMessage unvisible"
            }
            onClick={() =>
              sAction.dataSet("reportWindow/filterAlert/alert", false)
            }
          >
            {data.filterAlert.get("message")}
            <div className="closeMessage deleteIconWhite" />
          </div>

          {data.group != null && (
            <ReportWindowGroup
              data={data.group}
              fields={data.fields}
              relate={data.relate}
              module={data.module}
              way={way + "/group"}
            />
          )}
        </div>
      </div>
    );
  }
}
export default ReportWindowContent;
