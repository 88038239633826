
import detailDefault from "../../detailDefault";
export default class detailQuotes extends detailDefault{
    load(sAction,data) {

    }
    update(sAction,data) {
        if(data.field == "danova_sazba"){
            sAction.dataSet(data.prefix+"/customData/productData/dph", data.value);
        }
    }

}
