export default function homeLayoutSave(callback) {
  const data = this.dataGet("view").toJS();
  // console.log(data);

  this.setStorage("homeActiveTab", data.activeId);

  data.tabs.forEach((t, ti) => {
    t.cols.forEach((c, ci) => {
      c.rows.forEach((r, ri) => {
        if (!r.def) {
          return;
        }
        if (r.def.type == "view" || r.def.type == "calendar") {
          data.tabs[ti].cols[ci].rows[ri].data.viewData = "";
          data.tabs[ti].cols[ci].rows[ri].data.dashletId = ti+"_"+ci+"_"+ri;
          this.dataSet("view/tabs/"+ti+"/cols/"+ci+"/rows/"+ri+"/data/viewData/dashletId",ti+"_"+ci+"_"+ri)
        }
      });
    });
  });

  this.rest.post("home/savedashboard", data, function(data) {
    if(callback){
      callback();
    }
  });
}
