export default function saveMassUpdate(way){
    

    this.confrim(this.translate("LBL_MASS_UPDATE_CONFIRM_MESSAGE"), () => {
        this.popupHide();
        this.load();
        const data = this.dataGet(way);

        var fields = {};
        data.changes.get("fields").forEach((val, field) =>{
            if(field.indexOf("account_name|") === 0){
                field = field.replace("account_name|", "");
                val = data.fields.get(field).value;
            }
            fields[field] = val;
        });

        const paramData = {
            module: data.module,
            records : data.selected,
            changes: {fields: fields},
            filter: data.filterData,
        };

        this.rest.post("massupdate" , paramData, resp => {
            this.unLoad();
            if(resp.errCode == "OK"){
                this.closeRightPanel();
                this.route();
            }
            else{
                this.error(resp.errMsg);
            }
        });
    });
}