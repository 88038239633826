export default function emailFillComposeCache(props) {
  let {
    way,
    data: { to_addrs, cc_addrs, uid, account, emailTemplate, keepSelf }
  } = props;

  // this.load();
  this.rest.post("email/fillComposeCache", {}, data => {
    let signatures = [];
    let emailTemplates = [];
    let fromAccounts = [];
    let accountId = "";

    data.emailTemplates.forEachObject((label, value) => {
      emailTemplates.push({ label, value });
    });

    data.signatures.forEachObject((label, value) => {
      signatures.push({ label, value });
    });

    // kdyz odpovidame vsem, ze seznamu se prijemcu se vymaze nas e-mail
    // abychom neodpovidali sami i sobe
    let outboundAccNum = 0;
    if (Array.isArray(data.fromAccounts)) {
      data.fromAccounts.forEach(a => {
        outboundAccNum++;
        fromAccounts.push({ label: a.text, value: a.value });
        if (!keepSelf) {
          to_addrs = this.emailDeleteFromStringList(a.text, to_addrs);
          cc_addrs = this.emailDeleteFromStringList(a.text, cc_addrs);
        }
      });
    }
    if (!outboundAccNum) {
      this.popupHide();
      this.error("Nemáte vyplněný žádný odchozí e-mailový účet. Vyplňte jej prosím v nastavení e-mailového klienta.");
    }

    if (data.defaultAccount && Array.isArray(data.fromAccounts) && data.fromAccounts.indexOf(data.defaultAccount) !== -1) accountId = data.defaultAccount;
    else if (Array.isArray(data.fromAccounts) && data.fromAccounts[0]) accountId = data.fromAccounts[0].value;

    this.dsClear();
    this.dsAdd("set", way + "/emailTemplates", emailTemplates, "", "Select");
    this.dsAdd("set", way + "/signatures", signatures, "", "Select");
    this.dsAdd("set", way + "/fromAccounts", fromAccounts, "", "Select");
    if (!account) this.dsAdd("set", way + "/account", accountId);
    this.dsAdd("set", way + "/to_addrs", to_addrs);
    this.dsAdd("set", way + "/cc_addrs", cc_addrs);
    this.dsAdd("set", way + "/filledComposeCache", true);

    const signatureId = data.signature;

    if (!uid && signatureId) { //pokud se neodpovida na nejaky email - nacti podpis a pridej ho
      this.dsAdd("set", way + "/signatureId", signatureId);
      this.rest.post("email/getSignature", { id: signatureId }, data2 => {
        this.emailChangeSignature(data2.signature_html, props);
      });
    } else {
      this.dsAdd("set", way + "/loaded", true);
    }

    this.dsProcess();

    //byla predvolena sablona podle id nebo jmena - nacti ji
    if (emailTemplate) {
      this.unLoad();
      let id = "";
      let i;
      for (i of emailTemplates) {
        if (i.label === emailTemplate || i.value === emailTemplate) {
          id = i.value;
          break;
        }
      }

      if (id) this.emailChangeTemplate(id, props);
    }
  });
}
