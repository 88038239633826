import React, { PureComponent } from "react";
import  sAction  from "sAction";

export default class DetailViewActivityButtons extends PureComponent {
  composeEmail = e => {
    e.stopPropagation();
    e.preventDefault();

    const {prefix, module, id, name} = this.props;

    let to_addrs = "";
    if (module === "acm_CampaignsAction") {
      //todo: ziskej emaily ucastniku

    } else {
      let email = sAction.dataGet(prefix+"/fields/email1/def/emails");
      if (email) email = email.find(r => r.get("primary_address") === "1");
      if (email) to_addrs = email.get("email_address");
    }
    const email = sAction
      .dataGet(prefix + "/fields/email1/def/emails")
      .find(r => r.get("primary_address") === "1");
    if (email) to_addrs = email.get("email_address");

    const data = {
      parent_type: module,
      parent_id: id,
      parent_name: name,
      to_addrs
    };

    sAction.openEmailPopup(data);
  };

  render() {
    let {
      activities,
      parentModule,
      parentId,
      parentName,
      prefix
    } = this.props;

    if (!activities) return "";
    var links = [];

    const activitiesPole = ["Calls", "Meetings", "Notes", "Tasks"];

    //na firme lze vytvorit i kontakt
    if (parentModule === "Accounts") {
      activitiesPole.unshift("Contacts");
      activities = activities.set("contacts", 1);
    }

    activitiesPole.forEach(module => {
      if(sAction.hasAccess(module,"edit")){
      if (
        activities.get(module.toLowerCase()) == "1"
      ) {
        const params = {
          module,
          parentModule,
          parentId,
          parentName,
          prefix,
        };
        if (module === "Contacts") {
          params.relate_simple = {
            fieldName: "account_name",
            name: "account_id",
            value: {
              name: parentName,
              id: parentId
            }
          }
        }
        links.push(
          <a
            target="_blank"
            title={sAction.translate("LBL_NEW_" + module.toUpperCase())}
            key={module}
            onClick={() => sAction.newSubRecord(params)}
          >
            <span className={"icon-" + module} />
          </a>
        );
      }
    }
    });
    if (activities.get("emails") === "1" && sAction.hasAccess("Emails","edit")) {
      links.push(
        // Poslat e-mail
        <a
          target="_blank"
          title={sAction.translate("LBL_COMPOSE_EMAIL_BUTTON_LABEL")}
          key={"emails"}
          onClick={this.composeEmail}
        >
          <span className={"icon-Emails"} />
        </a>
      );
    }

    return !links.length ? "" : <div className="action">{links}</div>;
  }
}
