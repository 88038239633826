import React from "react";
import PureComponent from "../pure";

import sAction from "sAction";
import EnumField from "./filterFields/enumField";
import BoolField from "./filterFields/boolField";
import TextField from "./filterFields/textField";
import DateField from "./filterFields/DateField";
import ParentField from "./filterFields/ParentField"

class NewListViewHeaderField extends PureComponent {
  constructor() {
    super();
    this.state = {
      filterOpen: false,
      doOpen: true
    };
    this.iconContainer = React.createRef();
    this.headerInput = React.createRef();
    this.interval = null;
  }

  headerInputSearch(value,customParam = null) {
    const data = this.props.data;
    const prefix = this.props.prefix;

    var operand = "cont";
    if (data.def.get("type") == "enum") {
      operand = "eq";
    }
    if(data.def.get("type") == "multienum"){
      value = "^"+value+"^";
    }



    const paramData = {
      filters: [{ field: data.def.get("name"), value: value, type: operand, ...customParam }],
      prefix: prefix,
      operator: "and",
      field: data.def.get("name"),
      reload: true
    };
    sAction.addFilter(paramData);
  }

  openFilter() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const icon = this.iconContainer.current;
    const offset = icon.offsetLeft;
    console.log(offset);

    const scrollTable = document.querySelector(".newListViewContentInner");
    const scrollLeft = scrollTable.scrollLeft;
    console.log(scrollLeft);
    sAction.openFilter(data.def, offset - scrollLeft, prefix);
  }

  render() {
    const value = this.props.data;
    const orderBy = this.props.orderBy;
    const actFiltering = this.props.actFiltering;
    const openFilter = this.props.openFilter;
    const filtering = this.props.filtering;
    const prefix = this.props.prefix;

    const name = this.props.data.def.get("name");
    let defaultValue = null;
    if (this.props.filterValues && this.props.filterValues[name]) {
      defaultValue = this.props.filterValues[name];
    }


    var asc = this.props.asc;
    const sortable = this.props.sortable;
    const type = this.props.data.def.get("type");
    //__________________________________________ LABEL
    const fieldModule = value.def.get("fieldModule");
    const label = sAction.translate(value.label, fieldModule);
    if (asc == 1) {
      asc = 0;
    } else {
      asc = 1;
    }
    if (orderBy == false) {
      asc = 1;
    }
    //__________________________________________ LABEL END

    //___________________________________________ ICONA (serazeni/filtrace)
    var icon = null;
    if (actFiltering == false && sortable == true) {
      icon = (
        <div
          onClick={() => sAction.orderBy(value.key.toLowerCase(), asc, prefix)}
          className={"orderArrowsContainer listViewHeadericonContainer "}
        >
          <div
            className={
              orderBy == true && asc == 0
                ? "orderTriangleTop select"
                : "orderTriangleTop"
            }
          />
          <div
            className={
              orderBy == true && asc == 1
                ? "orderTriangleBottom select"
                : "orderTriangleBottom"
            }
          />
        </div>
      );
    } else if (actFiltering == true) {
      var usedMark = null;
      if (filtering.state == true) {
        usedMark = <div className="filteringUsedMark" />;
      }

      icon = (
        <div ref={this.iconContainer} className="listViewHeadericonContainer">
          <div
            onClick={() => this.openFilter()}
            className={"icon-filter listViewheaderFiltration"}
          >
            {usedMark}
          </div>
        </div>
      );
    }

    var field = null;
    var containerClass = "newListViewHeaderCell setWidth";
    if (actFiltering == true) {
      containerClass += " activeFiltering";
      switch (type) {
        case "ic":
        case "phone":
        case "currency":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                defaultValue={defaultValue}
                type={"number"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "enum":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <EnumField
                label={label}
                defaultValue={defaultValue}
                options={this.props.data.def.get("options")}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "multienum":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <EnumField
                label={label}
                options={this.props.data.def.get("options")}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "date":
        case "datetime":
        case "datetimecombo":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <DateField
                defaultValue={defaultValue}
                label={label}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "relate":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"text"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "parent":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <ParentField
                label={label}
                module={fieldModule}
                defaultValue={defaultValue}
                type={"text"}
                onChange={(e,customParam) => this.headerInputSearch(e,customParam)}
              />
            </div>
          );
          break;
        case "varchar":
        case "name":
        case "account_name":
        case "url":
        case "file":
        case "id":
        case "text":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"text"}
                defaultValue={defaultValue}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case 'bool':
          field = (
            <div className={"listviewHeaderInput " + type}>
              <BoolField
                label={label}
                defaultValue={defaultValue}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "int":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"int"}
                onChange={e => this.headerInputSearch(e)}
              />
            </div>
          );
          break;
        case "decimal":
          field = (
            <div className={"listviewHeaderInput " + type}>
              <TextField
                label={label}
                type={"decimal"}
                onChange={e => this.headerInputSearch(e.replace(",", "."))}
              />
            </div>
          );
          break;
        default:
          console.log('Nenalezen filtr pro typ: ' + type);
          break;
      }
    } else {
      field = (
        <div
          className="newListViewHeaderCellLabel"
          onClick={() =>
            sortable && sAction.orderBy(value.key.toLowerCase(), asc, prefix)
          }
        >
          {label}
        </div>
      );
    }
    //___________________________________________ ICONA (serazeni/filtrace) END
    return (
      <div
        data-width={value.width}
        className={containerClass}
        data-last={this.props.last}
      >
        {field}
        <div className="newListViewHeaderCellIconSet">
          {icon}
          <div
            className="tab-separatorIcon changeWidthHolder"
            onMouseDown={e => sAction.rowResize(e,prefix)}
          />
        </div>
      </div>
    );
  }
}
export default NewListViewHeaderField;
