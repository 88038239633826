import React from "react";

import Button from "../../formElements/Button";
import sAction from "sAction";

export default class File extends React.PureComponent {
  constructor(props) {
    super(props);

    this.canDownload = true;
  }
  newFile = () => {
    sAction.processFileInput({ multiple: false, way: this.props.way }, file => {

      sAction.uploadCacheFile(file, files => {
        this.canDownload = false;
        const file = files.ids[0];
        sAction.saveField({
          way: this.props.way,
          name: this.props.data.name,
          type: this.props.data.def.get("type"),
          value: file
        });
      });
    });
  };

  deleteFile = () => {
    sAction.saveField({
      way: this.props.way,
      name: this.props.data.name,
      type: this.props.data.def.get("type"),
      value: { deleteFile: true }
    });
  }

  render() {
    let value = "";
    let data = this.props.data;
    const module = this.props.module
    console.log(module);
    if (data.value) {
      if (this.canDownload) {
        value = (
          <a
            className="fileLink"
            onClick={() =>
              sAction.downloadFile({
                id: data.def.getIn(["file", "id"]),
                module: "FieldFile",
                name: data.value,
                params: sAction.objectToFormdata({ forceDownload: true })
              })
            }
            title={data.value}
          >
            {data.value}
          </a>
        );
      } else {
        value = <span className="fileName" title={data.value}>{data.value}</span>;
      }
    }

    const deleteFile = <Button className="onlyIconButton blueTextButton"
      onClick={this.deleteFile}
      title={"Smazat"}
    >
      <div className="icon-deleteIcon" />
    </Button>

    return (
      <div className="detailViewFieldFile">
        {value || <span className="fileName">{"žádná příloha"}</span>}
        <div className="buttons">
          {value && deleteFile}
          <Button className="onlyIconButton blueTextButton"
            onClick={this.newFile}
            title={sAction.translate("LBL_ADD_FILE", "Emails")}
          >
            <div className="icon-upload" />
          </Button>
        </div>
      </div>
    );
  }
}
