import React from "react";
import PureComponent from "../pure";

import Select from "../formElements/Select";

import  sAction  from "sAction";

class ListViewLooks extends PureComponent {
  selectLook(e) {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const value = e.target.value;
    if(prefix == "view"){
      localStorage.setItem(this.props.module+"_saved_search", value);
    }
    sAction.selectLook(e.target.value, prefix);
  }
  render() {
    const data = this.props.data;
    var options = [
      { value: " ", label: sAction.translate("LBL_ALL") },
      { value: "only_my", label: sAction.translate("LBL_ONLY_MY")}
    ];
    //  options.push({value:"All",label:sAction.translate("LBL_LIST_LOOKS_ALL")})
    if (data != undefined) {
      data.forEach(element => {
        options.push({ value: element.get("id"), label: element.get("name") });
      });
    }
    var defValue = " ";
    if( this.props.prefix == "view"){
      var lsVal = localStorage.getItem(this.props.module+"_saved_search");
      if(lsVal){
        defValue = lsVal;
      }
    }
    var select = (
      <Select
      defaultValue={defValue}
        containerClassName="withBorder"
        key={window.location.hash}
        options={options}
        onChange={e => this.selectLook(e)}
      />
    );

    return (
      <div className="listViewLooksContainer ">
        <div className="listViewLooksLabel">
          {sAction.translate("LBL_LIST_LOOKS")}:
        </div>
        <div className="listViewLooksContent">{select}</div>
      </div>
    );
  }
}
export default ListViewLooks;
