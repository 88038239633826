
export default function emailGetCitation (data) {
  //ocitovani puvodni zpravy

  //bug: (strip_tags + allowed tags) kdyz zprava obsahuje "Od: AB <a@b.cz>", do databaze se ulozi jen "Od: AB ";
  return (
    "<signature><p><br></p>...</signature><br />" +
    "<br />"+this.translate(
    "LBL_EMAIL_ORIGINAL_MESSAGE",
    "Emails"
    )+"<br />" +
    "Od: " +
    this.htmlEscape(data.from_addr) +
    "<br />" +
    this.translate("LBL_TO", "Emails") +
    " " +
    (data.toaddrs != null ? this.htmlEscape(data.toaddrs.replace(/<a .*<\/a>/g, "")) : "") +
    "<br />" +
    this.translate("LBL_CC", "Emails") +
    this.htmlEscape(data.cc_addrs) +
    "<br />Datum: " +
    this.mysqlToDate(data.date_start + " " + data.time_start, true) +
    "<br />"+this.translate("LBL_EMAIL_SUBJECT","Emails")+": " +
    data.name +
    "<br /><br />" +
    (data.description ? data.description : this.translate("LBL_EMAIL_NO_TEXT","Emails")) +
    "<br /><br />"
  );
};