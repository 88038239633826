import React from "react";
import PureComponent from "../pure";
import InputText from "../formElements/InputText";

import Breadcrumb from "./breadcrumb";
import NotificationCenter from "../notification/NotificationCenter";
import  sAction  from "sAction";

class Header extends PureComponent {
  constructor() {
    super();
    this.searchInput = React.createRef();
  }
  handleSearch() {
    const val = this.searchInput.current.value;
    sAction.href("#globalSearch/" + encodeURI(val));
    sAction.route();
  }

  searchKeyUp(ev) {
    if (ev.keyCode == 13) {
      this.handleSearch();
    }
  }
  alertMessageClick(){
    const alertMessage = this.props.alertMessage;
    if(sAction[alertMessage.get("buttonAction")] != undefined){
      sAction[alertMessage.get("buttonAction")]();
    }else{
      console.log("Akce "+alertMessage.get("buttonAction")+" není definována");
    }
  }

  render() {
    const user = this.props.user;
    const recordName = this.props.recordName;
    const breadcrumbData = sAction.breadcrumb(recordName);
    const alertMessage = this.props.alertMessage;
    var menuButton = (
      <div className="burgerMenu" onClick={() => sAction.menuOpen()} />
    );
    menuButton = null;

    const notificationData = this.props.not;
    return (
      <header>
        {alertMessage.get("show") == true && (
          <div className="headerAlertPanel">
            {alertMessage.get("message")}
            {alertMessage.get("buttonAction") != undefined && 
            <a onClick={() => this.alertMessageClick()}>{alertMessage.get("buttonLabel")}</a>
            }
          </div>
        )}
        <div className="headerContent">
          <div className="headerLeftContainer">
            <div className="headerLogoContainer">
              <a href="#home" className="headerLogoContent">
                <img className="headerLogo" src="img/coripo-logo.png" />
              </a>

              <a className="logo" href="#home">
                {sAction.param.companyName}
              </a>
            </div>
            <Breadcrumb data={breadcrumbData} />
          </div>
          {menuButton}
          <div className="userMenu">
            <InputText
              containerClassName="globalSearchInput"
              type="text"
              placeholder={sAction.translate("LBL_GLOBAL_SEARCH_PLACEHOLDER")}
              myRef={this.searchInput}
              onKeyUp={ev => this.searchKeyUp(ev)}
            />
            <div
              className="icon-search userHeaderButton"
              onClick={() => this.handleSearch()}
            />
            <NotificationCenter data={notificationData} />
            <div
              className="icon-filter headerFiltering userHeaderButton"
              onClick={() => sAction.openReportWindow()}
            />

            <div className="userMenuContainer">
              <a
                href={"#detail/Users/" + user.id}
                className="userMenuContainerHeader"
              >
                <div className="icon-avatar profileIcon" />
                <div className="userName">{user.name}</div>
              </a>
              <div className="userMenuContent">
                {user.is_admin === "1" && (
                  <a href={"#admin"}>
                    <div className="userMenuContentLine"><div className="icon-admin profileIcon" /> Admin</div>
                  </a>
                )}
                <a href={"#list/acm_wiki"}>
                    <div className="userMenuContentLine"><div className="icon-support profileIcon" />{sAction.translate("LBL_PODPORA")}</div>
                  </a>
                <div
                  className="userMenuContentLine"
                  onClick={() => sAction.logOut()}
                >
                  <div className="icon-logout profileIcon" />
                  Odhlásit se
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
