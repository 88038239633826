export default function loadCustomReport(id, way, data = null) {
  console.log(data);
  this.customReportXHR(id,data,reponseData => {
    this.dsClear();
    if(data.isDashboard != true){
      this.dsAdd("set",way, {});
    }
    this.dsAdd("set",way+"/html", reponseData);
    this.dsProcess();
  })
}
