import React, { Component } from "react";
import PureComponent from "../../pure";

import ReportWindowContentFieldLine from "./ReportWindowContentFieldLine";
import ReportWindowRelateLine from "./ReportWindowRelateLine";

import Select from '../../formElements/Select'

import  sAction  from "sAction";

class ReportWindowGroup extends PureComponent {


  componentDidUpdate(){
    const top = this.refs.topPlace
    const container = this.refs.childContainer
    const children = container.children
    const first = children[0];
    const topHeight = (first.clientHeight / 2) - 1
    top.style.height = topHeight+"px";

  }

  operatorChange(val){
    var way = this.props.way;
    const index = this.props.index;
    if(index != undefined){
      way = way+"/"+index
    }
    sAction.dataSet(way+"/operator",val.target.value)

  }

  render() {
    const data = this.props.data;
    const relate = this.props.relate;
    const fields = this.props.fields;
    const module = this.props.module
    var way = this.props.way;
    var wayWithoutIndex = way;
    const index = this.props.index;
    if(index != undefined){
      way = way+"/"+index
    }

    const childrenRender = [];
    data.children.forEach((child, key) => {
      if (child.type == "field") {
        childrenRender.push(
          <ReportWindowContentFieldLine
            data={child}
            fields={fields}
            relate={relate}
            key={key}
            way={way + "/children"}
            index={key}
            module ={module}
          />
        );
      } else if (child.type == "relate") {
        childrenRender.push(
          <ReportWindowRelateLine
            data={child}
            fields={fields}
            relate={relate}
            key={key}
            way={way + "/children"}
            index={key}
            module={module}
          />
        );
      } else if (child.type == "group") {
        childrenRender.push(
          <ReportWindowGroup
            data={child}
            fields={fields}
            relate={relate}
            key={key}
            way={way + "/children"}
            index={key}
            module={module}
          />
        );
      }
    });

     

    return (
      <div className="reportWindowGroup">
        <div className="reportWindowGroupOperator">
        <div ref="topPlace" className="topWhitePlace"></div>
        <div className="bottomWhitePlace"></div>
        <div className="reportWindowHorizontalLine"></div>
        {this.props.index != undefined &&
          <div onClick={() => sAction.dataDelete(wayWithoutIndex,index)} className="reportWindowDeleteLine iconCancel" title={sAction.translate("LBL_REPORT_REMOVE_GROUP")}>

          </div>
        }
        <div className="reportWindowHorizontalLine"></div>
          <Select containerClassName="withBorder reportWindowGroupSelect" defaultValue={data.operator} onChange={(val) => this.operatorChange(val)} options={[{value:"and",label:"AND"},{value:"or",label:"OR"}]} defaultValue={data.operator} />
          <div className="reportWindowHorizontalLine"></div>

        </div>
        <div ref="childContainer" className="reportWindowGroupList">
          {childrenRender}
          <div
            className="reportWindowAddToGroup"
          >
          <div className="reportWindowHorizontalLine"></div>
            <div className="addButton" onClick={() => sAction.reportWindowAddToGroup(way,"field")}>
            <div className="icon-addIcon addButtonIcon"></div> {sAction.translate("LBL_REPORT_LINE")}
            </div>
            <div className="addButton" onClick={() => sAction.reportWindowAddToGroup(way,"group")}>
            <div className="icon-addIcon addButtonIcon"></div> {sAction.translate("LBL_REPORT_GROUP")}
            </div>
          </div>
        </div>
       
      </div>
    );
  }
}

export default ReportWindowGroup;
