import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";

import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import { EmailPopupActionPanel } from "./shared/EmailPopupActionPanel";
import Loader from "../../loader";
import ErrorMessage from "../../default/ErrorMessage";

export default class EmailSettingsOePopup extends PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: {
        has_password: "",
        id: "",
        mail_sendtype: "",
        mail_smtpauth_req: "",
        mail_smtppass: "",
        mail_smtpport: "",
        mail_smtpserver: "",
        mail_smtpssl: "",
        mail_smtptype: "",
        mail_smtpuser: "",
        name: "",
        type: "",
        user_id: ""
      },
      expand_pass: false,
      expand_test: false,
      loaded: false,
      errors: [],
      error_msg: [],
      is_new: false,
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState(prevState => ({
      data: { ...prevState.data, [name]: value }
    }));
    if (name === "mail_smtpssl") {
      this.setState(prevState => ({
        data: { ...prevState.data, mail_smtpport: !value ? "25" : "465" }
      }));
    }
  };

  componentDidMount() {
    this._isMounted = true;

    const { id: outbound_email } = this.props;
    if (!outbound_email) {
      this.setState({ loaded: true, expand_pass: true, is_new: true });
      return;
    }

    sAction.rest.post("email/editOutbound", { outbound_email }, data => {
      if (!this._isMounted) return;
      this.setState({ loaded: true, data });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isFormValid = () => {
    const {
      // has_password,
      // id,
      // mail_sendtype,
      // mail_smtpauth_req,
      mail_smtppass,
      mail_smtpport,
      mail_smtpserver,
      // mail_smtpssl,
      // mail_smtptype,
      mail_smtpuser,
      name
      // type,
      // user_id
    } = this.state.data;

    const errors = [];
    const error_msg = [];
    if (!mail_smtpport) errors.push("mail_smtpport");
    if (!mail_smtpserver) errors.push("mail_smtpserver");
    if (!name) errors.push("name");

    this.setState({ errors, error_msg });

    if (errors.length) {
      // Nejsou vyplněny všechny povinné položky
      this.setState({ error_msg: [sAction.translate("ERR_FILL_REQUIRED_ITEMS", "Emails")] });
      return false;
    }

    return true;
  };

  //ulozeni zmen v popupu
  savePopup = () => {
    if (!this.isFormValid()) return false;

    //ne vsechny nazvy promennych jsou stejne
    const sendData = {
      mail_id: this.state.data.id,
      type: this.state.data.type,
      mail_sendtype: this.state.data.mail_sendtype,

      mail_name: this.state.data.name,
      mail_smtpserver: this.state.data.mail_smtpserver,
      mail_smtpport: this.state.data.mail_smtpport,
      mail_smtpauth_req: this.state.data.mail_smtpauth_req,
      mail_smtpssl: this.state.data.mail_smtpssl,
      mail_smtpuser: this.state.data.mail_smtpuser,
      mail_smtppass: this.state.data.mail_smtppass,
      mail_smtptype: this.state.data.mail_smtptype
    };
    sAction.load();
    sAction.rest.post("email/saveOutbound", sendData, data => {
      sAction.unLoad();
      sAction.popupHide();
      if (this.props.data && this.props.data.get("callback")) {
        if (!sendData.mail_id) sendData.mail_id = data;
        this.props.data.get("callback")(sendData);
      } else if (this.props.onChange) {
        this.props.onChange();
      }
    });
  };

  render() {
    const {
      expand_pass,
      loaded,
      errors,
      error_msg,
      data: {
        has_password,
        id,
        mail_sendtype,
        mail_smtpauth_req,
        mail_smtppass,
        mail_smtpport,
        mail_smtpserver,
        mail_smtpssl,
        mail_smtptype,
        mail_smtpuser,
        name,
        type,
        user_id
      }
    } = this.state;

    if (!loaded) return <Loader />;

    return (
      <div className="email__popup">
        <div className="acmPopupHeader">
          {/*Nastavení odchozího mail serveru*/}
          {sAction.translate("LBL_EMAIL_ACCOUNTS_OUTBOUND")}
        </div>

        <EmailPopupActionPanel onSave={this.savePopup} />

        <div className="email__wrapper">
          {!!error_msg.length && <ErrorMessage>{error_msg}</ErrorMessage>}

          <InputText
            required
            margin="dense"
            // inputRef={this.to_addrsRef}
            id="name"
            // label="Jméno"
            label={sAction.translate("LBL_EMAIL_ACCOUNTS_NAME")}
            type="text"
            name="name"
            error={errors.includes("name")}
            value={name}
            onChange={this.handleChange}
          />
          <InputText
            required
            margin="dense"
            // inputRef={this.to_addrsRef}
            id="mail_smtpserver"
            // label="SMTP server"
            label={sAction.translate("LBL_EMAIL_ACCOUNTS_SMTPSERVER")}
            type="text"
            name="mail_smtpserver"
            error={errors.includes("mail_smtpserver")}
            value={mail_smtpserver}
            onChange={this.handleChange}
          />
          <InputText
            margin="dense"
            // inputRef={this.to_addrsRef}
            id="mail_smtpuser"
            // label="SMTP uživatelské jméno"
            label={sAction.translate("LBL_EMAIL_ACCOUNTS_SMTPUSER")}
            type="text"
            name="mail_smtpuser"
            error={errors.includes("mail_smtpuser")}
            value={mail_smtpuser}
            onChange={this.handleChange}
          />
          {expand_pass ? (
            <InputText
              margin="dense"
              // inputRef={this.to_addrsRef}
              id="mail_smtppass"
              // label="SMTP heslo"
              label={sAction.translate("LBL_EMAIL_ACCOUNTS_SMTPPASS")}
              type="password"
              name="mail_smtppass"
              value={mail_smtppass}
              onChange={this.handleChange}
            />
          ) : (
            // Změnit heslo
            <a onClick={() => this.setState({ expand_pass: true })}>
              {sAction.translate("LBL_CHANGE_PASSWORD")}
            </a>
          )}
          <InputText
            required
            margin="dense"
            // inputRef={this.to_addrsRef}
            id="mail_smtpport"
            // label="SMTP port"
            label={sAction.translate("LBL_EMAIL_ACCOUNTS_SMTPPORT")}
            type="text"
            name="mail_smtpport"
            error={errors.includes("mail_smtpport")}
            value={mail_smtpport}
            onChange={this.handleChange}
          />
          <Select
            required
            options={[
              { label: sAction.translate("LBL_NONE","Emails"), value: "" },
              { label: "SSL", value: "1" },
              { label: "TLS", value: "2" }
            ]}
            // label="Povolit SMTP přeš SSL nebo TLS?"
            label={sAction.translate("LBL_EMAIL_SMTP_SSL_OR_TLS")}
            name="mail_smtpssl"
            error={errors.includes("mail_smtpssl")}
            value={mail_smtpssl}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}
