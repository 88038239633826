import React from "react";
import PureComponent from "../../pure";
import Button from "../../formElements/Button";
import Select from '../../formElements/Select';

import  sAction  from "sAction";
import Loader from "../../loader";



export default class OppDuplicateQuotePopup extends PureComponent {

    constructor(props){
        super(props);

        this.state = {
            quote: "",
            quotes: [],
            loaded: false
        }
    }

    handleCreate(){
        sAction.load();
        const params = {action: "opp_duplicate_quote", "quote": this.state.quote};

        sAction.rest.post("customAction", params, function(resp) {
            sAction.unLoad();
            if(resp.errCode == "OK"){
                sAction.href("#detail/Quotes/"+resp.record); 
            }
            else{
                sAction.error(resp.errMsg);
            }
        });
    }

    componentDidMount() {
        const opportunity_id = this.props.data.get("id");

        sAction.rest.post("getQuotesByOpportunity", {opportunity_id}, quotes => {
            if (!quotes || !quotes.length) {
                sAction.popupHide();
                // Nenalezeny žádné aktivní šablony.
                sAction.error(sAction.translate("LBL_NO_RELATED_QUOTES", "Opportunities"));
                return;
            }
            this.setState({quotes: quotes || [], loaded: true});
        });
    }

    render(){
        if (!this.state.loaded) return <Loader />;

        var quoteOptions = [];

        const quotes = this.state.quotes;
        quotes.forEach(quote =>{
            quoteOptions.push({value: quote.id, label: quote.quote_num + " ("+ quote.name + ")"});
        });

        return <React.Fragment>
        <div className="viewActionPanelButtons">
                <Button className="hoverGreen" onClick={() => this.handleCreate()}>
                    <div className={"icon-saveIcon calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CREATE_QUOTE", "Quotes")}
                </Button>
                <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                    <div className={"icon-close calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CANCEL_ACTION")}
                </Button>
            </div>
            
            <div className="templateCreatorFormContainer templateCreatorForm duplicateOppFormContainer">
            <div className="templateCreatorFormRow">
                <div className="quoteTemplNameLbl">
                    {sAction.translate("LBL_QUOTE", "Quotes")}
                </div>
                <Select 
                    open={false} 
                    name="quote"
                    options={quoteOptions} 
                    defaultValue={this.state.quote}
                    onChange={(ev) => this.setState({quote : ev.target.value})} 
                />
            </div>
        </div>
            </React.Fragment>;
    }
}