import React from "react";
import PureComponent from "ROOT/src/components/pure";
import { connect } from "react-redux";
import Header from "ROOT/src/components/header/header";
import Menu from "ROOT/src/components/menu/menu";
import Detail from "ROOT/src/components/detailView/detail";
import List from "ROOT/src/components/list/list";
import Home from "ROOT/src/components/home/home";
import Email from "ROOT/src/components/email/Email";
import Import from "ROOT/src/components/Import/Import";
import CalPageContainer from "ROOT/src/components/calendar/calPageContainer";
import Login from "ROOT/src/components/login/login";
import Popup from "ROOT/src/components/popup/popup";
import RightPanel from "ROOT/src/components/RightPanel/RightPanel";
import ErrorController from "ROOT/src/components/Errors/ErrorController";
import ErrorView from "ROOT/src/components/Errors/ErrorView"
import GlobalSearch from "ROOT/src/components/globalSearch/globalSearch";
import LeadConvertContainer from "ROOT/src/components/detailView/Leads/LeadConvertContainer";
import Merge from "ROOT/src/components/Merge/Merge"
import ReportContainer from 'ROOT/src/components/CustomReport/ReportContainer';
import Admin from 'ROOT/src/components/admin/admin';
import Loader from "ROOT/src/components/loader";
import Popper from "ROOT/src/components/popper/Popper"
import Toast from "ROOT/src/components/Toast/Toast"


class App extends PureComponent {
  render() {
    const store = this.props.store;
    const conf = store.appReducer.conf;
    const hash = window.location.hash
    var recordName = null;
    if(conf.view == "detail" || conf.view == "leadConvert"){
      recordName = store.appReducer.view.name
    }
    const routeData = {
      detail: (
        <Detail
          key="detail"
          a={this.props.action}
          data={store.appReducer.view}
          way="view"
        />
      ),
      import: <Import way="view" data={store.appReducer.view}/>,
      list: <List key="list" data={store.appReducer.view} way="view" />,
      home: <Home key="home" data={store.appReducer.view} way="view" />,
      email: <Email key="email" data={store.appReducer.view} way="view" />,
      calendar: (
        <CalPageContainer
          key="calendar"
          data={store.appReducer.calendar}
          user={conf.user}
          language={conf.language}
        />
      ),
      globalSearch : (<GlobalSearch data={store.appReducer.view} way="view" />),
      leadConvert : (<LeadConvertContainer data={store.appReducer.view} way="view" />),
      customReports: <ReportContainer data={store.appReducer.view} way="view" />,
      error: <ErrorView data={store.appReducer.view} />,
      admin: <Admin 
        data={store.appReducer.view} 
        back_version={conf.back_version} 
        front_version={conf.front_version} 
        way="view" 
      />,
      merge: <Merge data={store.appReducer.view} way="view" />
    };
    var page = null;
    if (conf.view == "login") {
      page = <Login key="login" version={conf.front_version} redirect={conf.redirect} />;
    } else {
      var content = null;
      if (conf.view != "") {
        content = routeData[conf.view];
      } else if (conf.view == "" && conf.load == false) {
        content = routeData["home"];
      }

      var pageClass = "";
      const alertMessageOpen = conf.alertMessage.get("show");
      if(store.appReducer.menu.open == false){
        pageClass += " min";
      }
      if(alertMessageOpen){
        pageClass += " alertMessageActive";
      }
      page = (
        <React.Fragment>
          <Header key="header" menu={conf.menuOpen} alertMessage={conf.alertMessage} user={conf.user} not={store.appReducer.notifications} 
            hash={hash}
            recordName={recordName}
          />
          <Menu key="menu" data={store.appReducer.menu} alertMessage={conf.alertMessage.get("show")} />
          <div
            key="viewContainer"
            id="page"
            className={pageClass}
          >
            {content}
          </div>
          {store.appReducer.rightPanel.show && (
            <RightPanel data={store.appReducer.rightPanel} />
          )}
        </React.Fragment>
      );
    }
    var Container = React.Fragment;
    // eslint-disable-next-line no-undef
    if (DEVMODE == true) {
      Container = ErrorController;
    }
    return (
      <Container>
        <div className="acmMaterial">
          {page}
          {conf.popup.show && (
            <Popup
              reportWindow={store.appReducer.reportWindow}
              data={conf.popup}
              way={"conf/popup"}
              calProps={{}}
            />
          )}
          {conf.popup2.show && (
            <Popup
              reportWindow={store.appReducer.reportWindow}
              way={"conf/popup2"}
              data={conf.popup2}
            />
          )}
          {conf.popper.size > 0 &&
            <Popper data={conf.popper} />
          }
          {conf.toast.size > 0 &&
            <Toast data={conf.toast} />
          }
          {conf.load == true && (
            <Loader fullscreen />
          )}
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    store: state
  };
};

export default connect(mapStateToProps)(App);
