import React from "react";
import InputText from "../formElements/InputText";
import Button from "../formElements/Button";

import Select from "../formElements/Select";
import  sAction  from "sAction";
import TickBox from "../formElements/TickBox";
import { EmailPopupActionPanel } from "../popup/email/shared/EmailPopupActionPanel";

export default class EmailAddContact extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      search: {
        search_field: "",
        person: "LBL_DROPDOWN_LIST_ALL",
        page: 1,
        start: 0,
        limit: 25,
        sort: "",
        dir: ""
      },
      users: [],
      selectedData: {},
      selectedUsers: {},
      loading: true
    };
    this.interval = "";

    const { data } = this.props;

    this.getEmails(data.get("to_addrs"), "normal");
    this.getEmails(data.get("cc_addrs"), "cc");
    this.getEmails(data.get("bcc_addrs"), "bcc");
  }

  getEmails = (emails, type) => {
    if (!emails) return;
    let i = 0;
    emails.split(/\s*[,;]+\s*/).forEach(email => {
      let name = "";
      if (email.indexOf("<") !== -1) {
        name = email.match(/^([^<]+)</);
        name = name ? name[1].trim() : "";
        email = email.match(/<([^>]+)>/);
        if (!email) return;
        email = email[1];
      }
      if (!name) {
        name = email;
      }

      this.state.selectedUsers[type + ++i] = {
        bean_id: type + i,
        name,
        email,
        type
      };
    });
  };

  find = (search = null) => {
    if (!this._isMounted) return;

    this.setState({ loading: true });

    sAction.rest.post("email/getAddressSearchResults", search, data => {
      this.setState({ users: data, loading: false });
    });
  };

  // kdyz admin odklikne nejaky checkbox
  handleCheckboxChange = (ch, user) => {
    //const u = event.target.value; // vybrany uzivatel id
    //const ch = event.target.checked; // hodnota checkboxu

    const selectedData = { ...this.state.selectedData };

    // zjisti zda mame v selectedData id vybraneho radku (a podle checkbox checked ho bud pridej nebo odeber)
    const index = user.bean_id in selectedData;
    if (index) {
      // ano
      if (ch === false) {
        // nalezeno - smazat
        delete selectedData[user.bean_id];
      }
    } else if (ch === true) {
      // pridej do array
      selectedData[user.bean_id] = user;
    }

    this.setState({ selectedData });
  };

  moveTo = (type = "normal") => {
    const selectedData = { ...this.state.selectedData };
    const selectedUsers = { ...this.state.selectedUsers };

    selectedData.forEachObject((v, k) => {
      selectedUsers[k] = { ...v, type };
    });

    this.setState({ selectedUsers, selectedData: {} });
  };

  addOrRemove = user => {
    const selectedUsers = { ...this.state.selectedUsers };

    if (user.bean_id in selectedUsers) {
      delete selectedUsers[user.bean_id];
    } else {
      selectedUsers[user.bean_id] = {
        ...user,
        type: "normal"
      };
    }

    this.setState({ selectedUsers });
  };

  renderEmails = () => {
    let { users, selectedData, loading } = this.state;
    if (selectedData == null) selectedData = {};

    if (loading) {
      return (
        <tr>
          <td />
          {/*Vyhledávání...*/}
          <td colSpan="3">{sAction.translate("LBL_SEARCHING", "Audit")}</td>
        </tr>
      );
    }

    if (users.length === 0 || users.TotalCount === 0) {
      return (
        <tr>
          <td />
          {/*Žádné výsledky.*/}
          <td colSpan="3">{sAction.translate("LBL_NO_RESULTS", "Audit")}</td>
        </tr>
      );
    }

    //TODO: presunout hodit gify do css
    return users.Person.map(user => (
      <tr key={user.bean_id}>
        <td>
          <TickBox
            checked={user.bean_id in selectedData}
            onChange={checked => this.handleCheckboxChange(checked, user)}
          />
        </td>
        <td>
          <img
            src={
              "http://localhost/rest_coripo/site/index.php?entryPoint=getImage&themeName=ACMARK&imageName=" +
              user.bean_module +
              ".gif"
            }
            alt=""
          />
        </td>
        <td onClick={() => this.addOrRemove(user)}>
          {sAction.htmlDecode(user.name)}
        </td>
        <td onClick={() => this.addOrRemove(user)}>{user.email}</td>
      </tr>
    ));
  };

  deleteSelectedEmail = email => {
    let selectedUsers = { ...this.state.selectedUsers };

    delete selectedUsers[email];

    this.setState({ selectedUsers });
  };

  changeSelectedEmail = (email, type = null) => {
    let { selectedUsers } = this.state;

    const change = !(selectedUsers[email]["change"] === true);

    selectedUsers = {
      ...selectedUsers,
      [email]: {
        ...selectedUsers[email],
        type: type != null ? type : selectedUsers[email].type,
        change
      }
    };

    this.setState({ selectedUsers });
  };

  renderSelectedEmails = () => {
    let { selectedUsers: users } = this.state;
    if (users == null) users = {};

    if (Object.keys(users).length === 0 && users.constructor === Object) {
      return (
        <tr>
          <td />
          {/*Nevybrali jste žádné uživatele.*/}
          <td colSpan="1">
            {sAction.translate("LBL_EMAIL_NO_USERS_CHOSEN", "Emails")}
          </td>
          <td />
        </tr>
      );
    }

    const types = {
      normal: sAction.translate("LBL_EMAIL_TO"), //"Komu",
      cc: sAction.translate("LBL_CC", "Emails"), //"Kopie (cc)",
      bcc: sAction.translate("LBL_BCC", "Emails") //"Skrytá kopie (bcc)"
    };

    return Object.keys(users).map(user => {
      user = users[user];
      let content = types[user.type];
      if (user.change == true) {
        content = (
          <select
            name="change_type"
            value={user.type}
            onChange={e =>
              this.changeSelectedEmail(user.bean_id, e.target.value)
            }
          >
            <option value={"normal"}>
              {sAction.translate("LBL_EMAIL_TO")}
            </option>
            {/*Komu*/}
            <option value={"cc"}>
              {sAction.translate("LBL_CC", "Emails")}
            </option>
            {/*Kopie (cc)*/}
            <option value={"bcc"}>
              {sAction.translate("LBL_BCC", "Emails")}
            </option>
            {/*Skrytá kopie (bcc)*/}
          </select>
        );
      } else {
        content = (
          <span onClick={() => this.changeSelectedEmail(user.bean_id)}>
            {content}
          </span>
        );
      }

      return (
        <tr key={user.bean_id}>
          <td>{content}</td>
          <td>{sAction.htmlDecode(user.name)}</td>
          <td>
            <div
              className="actionPanelButtonIcon deleteIcon pointerClass"
              onClick={() => this.deleteSelectedEmail(user.bean_id)}
            />
          </td>
        </tr>
      );
    });
  };

  componentDidMount() {
    this._isMounted = true;

    this.find(this.state.search);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = evt => {
    const search = {
      ...this.state.search,
      page: 1,
      start: 0,
      [evt.target.name]: evt.target.value
    };
    // console.log(search);
    this.setState({ search });
    // return;

    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.find(search);
    }, 1000);
  };

  //strankovani
  goToPage = page => {
    if (page < 1) return;

    let { search } = this.state;

    search = {
      ...search,
      page,
      start: (page - 1) * search.limit
    };

    this.setState({ search });

    this.find(search);
  };

  //strankovani
  sort = (sort, dir) => {
    let { search } = this.state;

    if (dir === "desc") {
      if (search.sort !== sort) {
        dir = "asc";
      }
    }

    search = {
      ...search,
      page: 1,
      start: 0,
      sort,
      dir
    };

    this.setState({ search });

    this.find(search);
  };

  savePopup = () => {
    let { selectedData, selectedUsers } = this.state;

    // pokud uzivatel nevybral zadne uzivatele
    if (Object.keys(selectedUsers).length === 0) {
      //zkontrolujeme, jestli je aspon zaskrtnul
      if (Object.keys(selectedData).length) {
        selectedUsers = selectedData;
      } else {
        // Nevybrali jste žádného adresáta
        // sAction.error(sAction.translate("ERR_NO_RECEPIENT_CHOOSED", "Emails"));
        // return;
      }
    }

    let results = [];
    selectedUsers.forEachObject(user => {
      results.push({
        type: user.type || "normal",
        email:
          user.name !== user.email
            ? user.name + " <" + user.email + ">"
            : user.email
      });
    });

    this.props.data.get("callback")(results);
  };

  render() {
    const { page, limit, dir } = this.state.search;
    const { users } = this.state;
    let totalCount = 0;
    if (users.TotalCount != null) {
      totalCount = parseInt(users.TotalCount, 10);
    }

    const optionsToSelect = [
      {
        value: "LBL_DROPDOWN_LIST_ALL",
        label: sAction.app_strings["LBL_DROPDOWN_LIST_ALL"]
      }, //"Všechny"
      { value: "users", label: sAction.app_strings["moduleList"]["Users"] }, //"Uživatelé"
      {
        value: "contacts",
        label: sAction.app_strings["moduleList"]["Contacts"]
      }, //"Kontakty"
      { value: "leads", label: sAction.app_strings["moduleList"]["Leads"] }, //"Zájemci"
      {
        value: "prospects",
        label: sAction.app_strings["moduleList"]["Prospects"]
      }, //"Prospekty"
      {
        value: "accounts",
        label: sAction.app_strings["moduleList"]["Accounts"]
      } //"Firmy"
    ];

    return (
      <React.Fragment>
        <div className="email__add-contact__wrapper">
          {/*Zvolte e-mail příjemce*/}
          <div className="acmPopupHeader">
            {sAction.translate("LBL_EMAIL_ADDRESS_BOOK_SELECT_TITLE")}
          </div>

          <div className="email__add-contact__wrapper__content">
            <EmailPopupActionPanel onSave={this.savePopup} />

            <div className="detailViewTabContent">
              <div className="email__add-contact">
                <div className="email__add-contact__search">
                  <div className="email__add-contact__search__input">
                    <InputText
                      margin="dense"
                      // label="Vyhledávání"
                      label={sAction.translate("LBL_EMAIL_SEARCHING")}
                      // placeholder="Hledejte jméno, e-mail, ..."
                      placeholder={sAction.translate(
                        "LBL_EMAIL_SEARCH_NAMES_EMAILS",
                        "Emails"
                      )}
                      type="text"
                      name="search_field"
                      //value="xyz"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="email__add-contact__search__select">
                    <Select
                      options={optionsToSelect}
                      onChange={this.handleChange}
                      name="person"
                      // label="Týká se"
                      label={sAction.translate("LBL_LIST_RELATED_TO", "Emails")}
                      //defaultValue={"Opportunities"}
                    />
                  </div>
                </div>

                <br />

                <div className="email__add-contact__container1">
                  <div className="email__add-contact__table1">
                    {/*Vložit do:{'\u00A0'}*/}
                    {sAction.translate("LBL_ADD_TO_ADDR", "Emails")}:{"\u00A0"}
                    <Button
                      onClick={() => this.moveTo()}
                      className="stillHover"
                    >
                      {/*Komu*/}
                      {sAction.translate("LBL_EMAIL_TO")}
                    </Button>
                    <Button
                      onClick={() => this.moveTo("cc")}
                      className="stillHover"
                    >
                      {/*Kopie (cc)*/}
                      {sAction.translate("LBL_CC", "Emails")}
                    </Button>
                    <Button
                      onClick={() => this.moveTo("bcc")}
                      className="stillHover"
                    >
                      {/*Skrytá kopie (bcc)*/}
                      {sAction.translate("LBL_BCC", "Emails")}
                    </Button>
                  </div>
                  <div className="divider" />
                  {/*Vybrané:*/}
                  <div className="email__add-contact__table1">
                    {sAction.translate("LBL_SELECTED_ADDR", "Emails")}
                  </div>
                </div>

                <div className="email__add-contact__container">
                  <div className="email__add-contact__table">
                    <table>
                      <thead>
                        <tr>
                          <th />
                          <th />
                          {/*Jméno*/}
                          <th
                            onClick={() =>
                              this.sort("name", dir == "asc" ? "desc" : "asc")
                            }
                          >
                            {sAction.translate("LBL_LIST_NAME")}
                          </th>
                          {/*E-mail*/}
                          <th
                            onClick={() =>
                              this.sort("email", dir == "asc" ? "desc" : "asc")
                            }
                          >
                            {sAction.translate("LBL_LIST_EMAIL")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>{this.renderEmails()}</tbody>
                    </table>
                  </div>
                  <div className="divider" />
                  <div className="email__add-contact__table">
                    <table>
                      <thead>
                        <tr>
                          {/*Typ*/}
                          <th style={{ width: 160 }}>
                            {sAction.translate("LBL_LIST_TYPE", "Emails")}
                          </th>
                          {/*Jméno*/}
                          <th>{sAction.translate("LBL_LIST_NAME")}</th>
                          <th style={{ width: 20 }} />
                        </tr>
                      </thead>
                      <tbody>{this.renderSelectedEmails()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="listViewFooter">
              <div className="paginationContainer">
                <div className="pagination">
                  <div
                    className="arrow iconPageStart"
                    onClick={() => (page != 1 ? this.goToPage(1) : "")}
                  />
                  <div
                    className="arrow iconPageBack"
                    onClick={() => this.goToPage(page - 1)}
                  />
                  <div className="pageCount">
                    {sAction.translate("LBL_ACTPAGE") + " " + page}
                  </div>
                  <div
                    className="arrow iconPageNext"
                    onClick={() =>
                      page < Math.ceil(totalCount / limit)
                        ? this.goToPage(page + 1)
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
