import React from "react";
import PureComponent from "../pure";
import ImportFileTable from "./ImportFileTable";
import ImportFileDelimiterSelect from "./ImportFileDelimiterSelect";
import ViewPanelMenu from "../viewPanelMenu/viewPanelMenu";
import ListViewTable2 from "../list/listViewTable2";
import sAction from "sAction";

class Import extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (sessionStorage.getItem('listImported') === 'true') {
            return;
        }

        let delimiterOptions = [];
        delimiterOptions.push(
            {value: '\n', label: <span className="importViewSelectorOptions">\n</span>},
            {value: '\r\n', label: <span className="importViewSelectorOptions">\r\n</span>},
            {value: '\r', label: <span className="importViewSelectorOptions">\r</span>},
            {value: ',', label: <span className="importViewSelectorOptions">,</span>},
            {value: ';', label: <span className="importViewSelectorOptions">;</span>},
            {value: '.', label: <span className="importViewSelectorOptions">.</span>},
        );
        this.setState({delimiters: delimiterOptions})
        sAction.raiseFileUpload({prefix: this.props.way});
    }

    changeLineDelimiter = (delimiter) => {
        sAction.dsClear();
        sAction.dsAdd('set', this.props.way + "/delimiters/lineDelimiter", delimiter);
        sAction.dsProcess();
        sAction.parseFile({prefix: this.props.way});
    }

    changeColumnDelimiter = (delimiter) => {
        sAction.dsClear();
        sAction.dsAdd('set', this.props.way + "/delimiters/columnDelimiter", delimiter);
        sAction.dsProcess();
        sAction.parseFile({prefix: this.props.way});
    }

    render() {
        const data = this.props.data;
        const prefix = this.props.way;
        let csvMapping;
        let table;
        let delimiterSelects = [];

        // znamena, ze mame nacteny soubor od uzivatele
        if (data.get('import')) {
            csvMapping = <ImportFileTable way={prefix + '/table'} data={data} key="mapping"/>;
            delimiterSelects.push(
                <div className="importViewSelectorContainer" key="delimiterSelects">
                <ImportFileDelimiterSelect options={this.state.delimiters} label={sAction.translate('LBL_COLUMN_DELIMITER')} key="columnDelimiter" value={data.toJS()['delimiters']['columnDelimiter']} onChange={this.changeColumnDelimiter}/>
                <ImportFileDelimiterSelect options={this.state.delimiters} label={sAction.translate('LBL_ROW_DELIMITER')} key="lineDelimiter" value={data.toJS()['delimiters']['lineDelimiter']} onChange={this.changeLineDelimiter}/>
                </div>
            );
        }

        // znamena, ze mame importovana data ze souboru od uzivatele a zobrazujeme vysledek ve forme listu
        if (data.get('listViewTree')) {
            table = <ListViewTable2 data={data} parent={this} forceRightPanel={true} prefix={prefix} key="importedList"/>;
        }

        return (
            <div className="importViewMainContainer">
                {data.get('buttons') &&
                <ViewPanelMenu data={data} prefix={prefix} />
                }

                    <div className="importViewContainer">
                        {delimiterSelects}
                        {table}
                        {csvMapping}
                    </div>

            </div>
        );
    }
}

export default Import;