export default function importFileMapping(params) {
    const module = this.getModuleName();
    const prefix = params.prefix;
    const data = this.dataGet(prefix).toJS();
    let mapping = data.table.header.mapping;
    const delimiters = data.delimiters;
    const fileId = params.fileId;
    const mappingData = {mapping: mapping, delimiters: delimiters, file: fileId};
    this.dsClear();
    this.dsAdd('set', 'conf/load', true);
    this.dsProcess();
    this.rest.post("import/" + module, mappingData, (data) => {
       this.loadImportedList(data, module, prefix);
    });
}