export default function reportWindowSetLineData(way, paramData) {
  var self = this;
  if (paramData.def != undefined && (paramData.def.type == "link" || paramData.def.type == "relate")) {
    this.rest.get("defs/" + paramData.def.module, data => {
      var fields = {
        fields: [],
        relate: []
      };
      data.forEachObject((field, key) => {
        if (field.type == "relate" || field.type == "link") {
          if (field.searchable == true) {
            fields.relate.push(field);
          }
        } else {
          fields.fields.push(field);
        }
      });
      fields["module"] = paramData.def.module;
      fields["select"] = paramData.select;
      fields["def"] = paramData.def;

      self.dsClear();
      self.dsAdd("set", way, fields, "reportRelateField");
      self.dsAdd("set", way + "/group", {}, "reportWindowGroup");
      self.dsAdd("set", "reportWindow/filterAlert/alert", false);
      self.dsAdd("set", "conf/load", false);
      self.dsProcess();

      self.reportWindowFindAllRelate();

    });
  } else {
    this.dsClear();
    paramData.forEachObject((value, key) => {
      console.log(value,key);
      self.dsAdd("set", way + "/" + key, value);
    });
    self.dsAdd("set", "reportWindow/filterAlert/alert", false);
    this.dsProcess();
  }
}
