import React from 'react';
import ListviewAddNewProspectList from "../../components/popup/listview/ListviewAddNewProspectList";

export default function addToProspectLists(params) {
  const data = {
    header: this.translate("LBL_ADD_TO_PROSPECT_LIST_BUTTON_LABEL"),
    //  color:"red",
    buttons: [
      {
        // Vytvořit
        label: this.translate("LBL_CREATE","ProspectLists"),
        callback: () => {
          this.popupHide();
          this.popup(<ListviewAddNewProspectList prefix={params.prefix} />);
          //window.open("#detail/ProspectLists", "_blank");
        },
      },
      {
        // Vybrat existující
        label: this.translate("LBL_CHOOSE_EXISTING","ProspectLists"),
        callback: () => {
          this.popupHide();
          const relateData = {
            module: "ProspectLists",
          };
          this.openRelatePopup(relateData, (returnData) => {
            const selected = this.dataGet(params.prefix + "/selected");

            // filter pro vybrane zaznamy v listview
            let filterData_lv = null;
            if(selected === "all"){
              let list = this.dataGet(params.prefix);
              let savedSearch = list.actSavedSearch;
              filterData_lv = this.reloadListParams({
                list,
                prefix: params.prefix,
                savedSearch,
                columns: false,
              });
              filterData_lv["selected"] = list.selected;
              filterData_lv["module"] = list.modul;
              filterData_lv["type"] = list.type;
            }

            // filter pro vybrane marketingove seznamy v popupu
            const prefix = "conf/popup/data/listView";
            const records = returnData.id;
            let filterData_p = null;
            if(records === "all"){
              let list = this.dataGet(prefix);
              let savedSearch = list.actSavedSearch;
              filterData_p = this.reloadListParams({
                list,
                prefix,
                savedSearch,
                columns: false,
              });
              filterData_p["selected"] = list.selected;
              filterData_p["module"] = list.modul;
              filterData_p["type"] = list.type;
            }

            var sendRestData = {
              uids: selected,
              uids_filter: filterData_lv,
              module: params.module,
              prospect_list_id: records,
              prospect_list_filter: filterData_p,
            };

            this.popupHide();

            this.load();
            this.rest.post("addToProspectLists", sendRestData, (returnRestData) => {
              this.unLoad();
              if (returnRestData) {
                this.alert("OK");
                this.dataSet(params.prefix + "/selected", []);
              } else {
                // nastala chyba
              }
            });
          });
        },
      },
    ],
  };

  // "Vybranou položku nebo položky můžete přiřadit k existujícímu seznamu nebo vytvořit nový."
  this.popup(this.translate("LBL_CREATE_OR_CHOOSE_EXISTING","ProspectLists"), data);
}


