import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import czLocale from "date-fns/locale/cs";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import sAction from "sAction"

const localeMap = {
    cz: czLocale,
};

export default class AcmDate extends React.Component {
    constructor(props) {
        super()
        console.log(props.value)
        this.state = {
            value: this.entryFormat(props.value),
            open:true,
        }
        this.input = React.createRef();
        this.dateFormat = sAction.getDateFormat("datepicker");
    }
    componentDidMount(props){
        if(this.props.autoOpen == true){
        this.input.current.click()
        }
    }
    entryFormat(date){
        if( ! date){
            return null
        }
        return date
    }
    onChange(date) {
        const dateObj = new Date(date)
        const month = dateObj.getMonth() < 9 ? "0" + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
        const day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate()

        const returnDate = dateObj.getFullYear() + "-" + month + "-" + day;
        this.setState({ value: date,open:false })
        if (this.props.onChange != undefined) {
            date = date === null ? null : returnDate;
            this.props.onChange(date);
        }
    }
    open(){
        console.log("ted");
    }
    render() {
        let value = this.state.value;
        if (this.props.defaultValue) {
            value = this.props.defaultValue;
        }
        console.log(this.state.value)
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["cz"]}>
                <DatePicker
                    className={"AcmDate " + this.props.className || null}
                    autoOk={true}
                    clearable
                    value={value}
                    autoFocus={this.props.autoFocus ?? false}
                    onChange={date => this.onChange(date)}
                    onClose={() => this.props.onClose != undefined ? this.props.onClose() : {}}
                    cancelLabel={sAction.translate("LBL_DATEPICKER_CANCEL")}
                    okLabel={sAction.translate("LBL_DATEPICKER_OK")}
                    clearLabel={sAction.translate("LBL_DATEPICKER_CLEAR")}
                    todayLabel={sAction.translate("LBL_DATEPICKER_TODAY")}
                    showTodayButton={true}
                    format={this.dateFormat}
                    inputRef={this.input}
                />
            </MuiPickersUtilsProvider>
        )
    }
}