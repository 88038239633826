import detailDefault from "../../detailDefault";
export default class detailacm_invoices extends detailDefault {
    load(sAction,data) {
        if (data.prefix === "rightPanel/data/detail"){
            sAction.dataSet(data.prefix + "/fields/cena_dph_celkem/def/readonly", true)
            sAction.dataSet(data.prefix + "/fields/cena_bez_dph_celkem/def/readonly", true)
            sAction.dataSet(data.prefix + "/fields/cena_s_dph_celkem/def/readonly", true)
        }
    }
  
    newSubRecord(sAction, data) {
        const currency = sAction.dataGet(data.prefix+"/fields/currency_id/value");
        if(sAction.detailPredefinedFields == null){
            sAction.detailPredefinedFields = [];
        }
        sAction.detailPredefinedFields.push({
            type:"field",
            "name":"currency_id",
            "value": currency
        })
    }
}
