import React, { PureComponent } from "react";
import  sAction  from "sAction";

import Select from "../formElements/Select";
import Button from "../formElements/Button";
import Relate from "../formElements/Relate";

export default class EmailRelateField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      parent_id: "",
      parent_type: "",
      parent_name: "",
      error1: false, // select field
      error2: false // input field
    };

    this.optionsToSelect = [
      { value: "Bugs", label: sAction.app_strings["moduleList"]["Bugs"] },
      {
        value: "acm_invoices",
        label: sAction.app_strings["moduleList"]["acm_invoices"]
      },
      {
        value: "Accounts",
        label: sAction.app_strings["moduleList"]["Accounts"]
      },
      {
        value: "acm_CampaignsAction",
        label: sAction.app_strings["moduleList"]["acm_CampaignsAction"]
      },
      {
        value: "Contacts",
        label: sAction.app_strings["moduleList"]["Contacts"]
      },
      { value: "Quotes", label: sAction.app_strings["moduleList"]["Quotes"] },
      {
        value: "Products",
        label: sAction.app_strings["moduleList"]["Products"]
      },
      { value: "Project", label: sAction.app_strings["moduleList"]["Project"] },
      {
        value: "Prospects",
        label: sAction.app_strings["moduleList"]["Prospects"]
      },
      {
        value: "Opportunities",
        label: sAction.app_strings["moduleList"]["Opportunities"]
      },
      { value: "Cases", label: sAction.app_strings["moduleList"]["Cases"] },
      { value: "Leads", label: sAction.app_strings["moduleList"]["Leads"] },
      { value: "Tasks", label: sAction.app_strings["moduleList"]["Tasks"] },
      {
        value: "acm_orders",
        label: sAction.app_strings["moduleList"]["acm_orders"]
      },
      {
        value: "ProjectTask",
        label: sAction.app_strings["moduleList"]["ProjectTask"]
      },
      {
        value: "Calls",
        label: sAction.app_strings["moduleList"]["Calls"]
      },
      {
        value: "Meetings",
        label: sAction.app_strings["moduleList"]["Meetings"]
      }
    ];

    this.optionsToSelect.sort(this.sortByLabel)
  }

  sortByLabel( a, b ) {
    const alabel = a && typeof a.label === "string" ? a.label : "";
    return alabel.localeCompare(b.label);
  }

  openPopupList = () => {
    const source = this.props.way ? this.props : this.state;
    const data = {
      module: source.parent_type
    };

    if (!data.module) {
      sAction.error(sAction.translate("LBL_ERROR_SELECT_MODULE", "Emails"));
      return;
    }

    sAction.openRelatePopup(data, returnData => {
      const { way } = this.props;
      if (way) {
        sAction.dsClear();
        sAction.dsAdd("set", way + "/parent_id", returnData.id);
        sAction.dsAdd("set", way + "/parent_name", returnData.name);
        sAction.dsAdd("set", way + "/updateEmailText", "relate");
        sAction.dsProcess();
      } else {
        this.props.onChange({ ...returnData, type: data.module });
        this.setState({
          parent_id: returnData.id,
          parent_name: returnData.name
        });
      }

      this.setState({ error2: false });
      sAction.popupHide();
    });
  };

  handleChange = e => {
    if (this.props.way) {
      sAction.dataSet(this.props.way + "/" + e.target.name, e.target.value);
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    this.setState({ error1: false });
  };

  update = data => {
    const { way } = this.props;
    if (way) {
      sAction.dsClear();
      sAction.dsAdd("set", way + "/parent_id", data.id);
      sAction.dsAdd("set", way + "/parent_name", data.name);
      sAction.dsAdd("set", way + "/updateEmailText", "relate");
      sAction.dsProcess();
    } else {
      this.props.onChange({ ...data, type: this.state.parent_type });
      this.setState({ parent_id: data.id, parent_name: data.name });
    }

    const source = this.props.way ? this.props : this.state;
    this.setState({ error1: !source.parent_type, error2: !data.id });
  };

  render() {
    const source = this.props.way ? this.props : this.state;
    const { parent_id, parent_type, parent_name } = source;

    return (
      <div className="email__relate">
        <div className="email__select1">
          <Select
            options={this.optionsToSelect}
            // label="Vztahující se k"
            label={sAction.translate("LBL_EMAIL_RELATE", "Emails")}
            name="parent_type"
            value={parent_type}
            onChange={this.handleChange}
            error={this.state.error1}
          />
        </div>
        <div className="email__select2">
          <Relate
            key={parent_id + parent_name}
            newRecord={false}
            data={{
              value: parent_name,
              // label: "Subjekt"
              label: sAction.translate("LBL_EMAIL_OBJECT", "Emails")
            }}
            buttons={[]}
            module={parent_type}
            callback={this.update}
            onBlur={this.onBlur}
            error={this.state.error2}
          />
        </div>
        <div className="email__compose__field-row__button">
          <Button onClick={this.openPopupList} className="stillHover">
            {/*Vybrat*/}
            {sAction.translate("LBL_EMAIL_SELECT")}
          </Button>
        </div>
      </div>
    );
  }
}
