import React from "react";
import PureComponent from "../pure";

import  sAction  from "sAction";

class SubMenuLines extends PureComponent {
  render() {
    const data = this.props.data;
    const activeModule = this.props.activeModule;
    var buttonsRender = [];
    data.forEach((button,index) => {
      const type = button.get("type");
      if (type == "module") {
        const module = button.get("value");
        const vname = sAction.app_strings.moduleList[module];
        buttonsRender.push(
          <a key={index} className={activeModule == module ? "subMenuButton select" : "subMenuButton"}
          href={"#list/"+module}
          >
            <div className={"icon-" + module+" subMenuIcon"} />
            {vname}
          </a>
        );
      }else if(type == "href"){
        const href = button.get("href");
        const iconClass = button.get("icon");
        const vname = sAction.translate(button.get("vname"));
        buttonsRender.push(
          <a key={index} className={activeModule == module ? "subMenuButton select" : "subMenuButton"}
          href={href}
          >
            <div className={iconClass} />
            {vname}
          </a>
        );
      }
    });

    return <div className="subMenuButtonsContainer">{buttonsRender}</div>;
  }
}
export default SubMenuLines;
