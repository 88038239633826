import React from "react";
import  sAction  from "sAction";
export default function NameCallback(props) {
  const value = sAction.decodeHTMLEntities(props.value);
  var lineRender;
  lineRender = (
    <div className="hrefEffect" onClick={() => props.callback()}>
      <a className="strong" title={value}>
        {value}
      </a>
    </div>
  );

  return (
    <div className={"newListViewLine " + props.extraClass}>{lineRender}</div>
  );
}
