import React from 'react'
import PureComponent from '../../pure'
class quickCreateActMenu extends PureComponent{

    constructor(props){
        super(props);

        this.state = {
            activeTab : props.activeTab,
        };

    }

    clickHandle(target){
        this.props.clickFnc(target);
        this.setState({activeTab: target});
    }

    render(){
        let meetClass = "";
        let callClass = "";
        let taskClass = "";

        switch(this.state.activeTab){
            case "detailMeeting":
                meetClass = " activeTab";
                break;
            case "detailCall":
                callClass = " activeTab";
                break;
            case "detailTask":
                taskClass = " activeTab";
                break;
        }

        let navClass = "quickCreateNav";

        if(this.props.menuOpen === false){
            navClass += " min";
        }

        return(
            <nav className={navClass}>
                <a className={"calQuicCreateTabMenuButton"+meetClass} onClick={() => {this.clickHandle("detailMeeting")}}><div className="calActMenuIcon icon-meetings"></div></a>
                <a className={"calQuicCreateTabMenuButton"+callClass} onClick={() => {this.clickHandle("detailCall")}}><div className="calActMenuIcon icon-calls"></div></a>
                <a className={"calQuicCreateTabMenuButton"+taskClass} onClick={() => {this.clickHandle("detailTask")}}><div className="calActMenuIcon icon-tasks"></div></a>
            </nav>
        );

    }

}export default quickCreateActMenu