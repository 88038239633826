import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import InputText from "../../formElements/InputText";
import Button from "../../formElements/Button";
import AcmDateTime from "../../formElements/AcmDatetime";
import Relate from "../../formElements/Relate";

export default class CreateMultipleCalls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userId: '',
            startDate: null,
            userIdEmpty: false,
            startDateEmpty: false,
            perDayEmpty: false,
            timeBetween: false,
        };
        // this.user = React.createRef();
        this.perDay = React.createRef();
        this.timeBetween = React.createRef();
        this.startDate = React.createRef();
    }

    setRelate(item) {
        this.setState({userName: item.name, userId: item.id});
        // sAction.dataSet('conf/popup/data/name', item.name);
    }

    createCalls(e) {
        if (this.state.userId && this.state.startDate && this.perDay.current.value && this.timeBetween.current.value) {
            const records = this.props.data.get('records');
            const prefix = "view";

            var filterData = null;
            if(records === "all"){
                const list = sAction.dataGet(prefix);
                const savedSearch = list.actSavedSearch;
                filterData = sAction.reloadListParams({
                    list,
                    prefix,
                    savedSearch,
                    columns: false,
                });
                filterData["selected"] = list.selected;
                filterData["module"] = list.modul;
                filterData["type"] = list.type;
            }

            const params = {
                user: this.state.userId,
                perDay: this.perDay.current.value,
                timeBetween: this.timeBetween.current.value,
                startDate: this.state.startDate,
                records,
                parentModule: this.props.data.get('module'),
                filter: filterData
            };
            sAction.createMultipleCallsSave(params);
        }
        let error = {};
        this.state.userId ? error.userIdEmpty = false : error.userIdEmpty = true;
        this.state.startDate ? error.startDateEmpty = false : error.startDateEmpty = true;
        this.perDay.current.value ? error.perDayEmpty = false : error.perDayEmpty = true;
        this.timeBetween.current.value ? error.timeBetweenEmpty = false : error.timeBetweenEmpty = true;
        this.setState(error);
    }

    setDate(date) {
        this.setState({startDate: date});
    }

    closePopup() {
        sAction.popupHide();
        this.props.close();
    }

    render() {
        let message = undefined;
        const userName = this.state.userName;
        const userId = this.state.userId;

        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_CREATE_MULTIPLE_CALLS')}</div>
                </div>
                <div className="">
                    {message !== undefined && (
                        <div className="tagMessage">{sAction.translate(message)}</div>
                    )}
                    <div className="multipleCallsTable">
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_CALLS_TIME_BETWEEN')}</div>
                            <div className="mcCell">
                                <InputText
                                    type='number'
                                    myRef={this.timeBetween}
                                    defaultValue='15'
                                    error={this.state.timeBetweenEmpty}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_CALLS_MAX_PER_DAY')}</div>
                            <div className="mcCell">
                                <InputText
                                    type='number'
                                    myRef={this.perDay}
                                    error={this.state.perDayEmpty}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_CALLS_FIRST_DATETIME')}</div>
                            <div className="mcCell">
                                <AcmDateTime
                                    myRef={this.startDate}
                                    key={this.state.startDate}
                                    onChange={e => this.setDate(e)}
                                    onBlur={e => this.setDate(e)}
                                    value={this.state.startDate}
                                    error={this.state.startDateEmpty}
                                    // className={'error'}
                                    // error={true}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            <div className="mcCell">{sAction.translate('LBL_MULTIPLE_CALLS_ASSIGNED_USER')}</div>
                            <div className="mcCell">
                                <Relate
                                    newRecord={false}
                                    module="Users"
                                    // value={userName}
                                    data={{
                                        value: userName,
                                        id_value: userId
                                    }}
                                    error={this.state.userIdEmpty}
                                    buttons={[]}
                                    callback={(item) => this.setRelate(item)}
                                    key={userId}
                                />
                            </div>
                        </div>
                        <div className="mcRow">
                            {/*<div className="mcCell"></div>*/}
                            <div className="mcCell mcButtons viewActionPanelButtons">
                                <Button
                                    className='popupCloseButton'
                                    onClick={e => this.closePopup()}
                                >
                                    <div className="actionPanelButtonIcon icon-closeIconLight" />
                                    {sAction.translate('LBL_EXPORT_CLOSE')}
                                </Button>
                                <Button
                                    onClick={e => this.createCalls(e)}
                                >
                                    <div className="actionPanelButtonIcon icon-addIcon" />
                                    {sAction.translate('LBL_MULTIPLE_CALLS_CREATE')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}