export default function newSubRecord(data) {
  // console.log(data);
  const { module, parentModule, parentId, parentName, prefix,rel, relate_simple } = data;
  if (!module) {
    console.log("není zadán modul");
    return;
  }
  if (!parentModule) {
    console.log("není zadán parent modul");
    return;
  }
  if (!parentId) {
    console.log("není zadán parent id");
    return;
  }
  if (!parentName) {
    console.log("není zadán parent name");
    return;
  }
  if (!prefix) {
    console.log("není zadán prefix");
  }
  this.detailPredefinedFields = [];
  const activitiesModules = ["Calls", "Meetings", "Tasks", "Notes"];

  if (activitiesModules.includes(module)) {
    this.detailPredefinedFields.push({
      type: "parent",
      name: "parent_name",
      parentName,
      parentId,
      parentModule
    });
  } else if (relate_simple) {
    this.detailPredefinedFields.push({
      type:"relate_simple",
      ...relate_simple
    });
  }else {
    if(!rel){
        console.log("nejsou zadány inforamce o vazbě");
        return;
    }
    this.detailPredefinedFields.push({
        type:"relate",
        module:parentModule,
        id:parentId,
        value:parentName,
        rel
    })
  }
  this.popupDetail({
    module,
    record: "",
    saveCallback: data => {
      this.reloadSubpanelByWay(prefix,module);
    },
    exitCallback: data => {

    }
  });
}
