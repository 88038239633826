import React from "react";

import Relate from "../Relate/Relate";
import Select from "../../formElements/Select";
import Input from "../../formElements/InputText";
import  sAction  from "sAction";

export default class Parent extends Relate {
  constructor() {
    super();
    this.state = {
      state: "fine",
      value: null,
      id: null,
      resultData: null,
      resultOpen: false,
      selectModule: null,
      resultArrowIndex: -1,
      options: [{ value: "", label: "" }],
      defaultValue: ""
    };
    this.searchInterval = null;
    this.input = React.createRef();
  }
  search() {
    const value = this.input.current.value;
    const module = this.getParentModule();
    if (value !== "") {
      const searchData = {
        module,
        fields: ["name"],
        getFields: ["id", "name"],
        value
      };
      var self = this;
      sAction.quickSearch(searchData, data => {
        self.setState({
          resultData: data,
          resultOpen: true
        });
      });
    }
  }
  deleteValue = () => {
    this.selectItem({ id: "", name: "" });
    this.moduleChange('');
  };
  selectItem(item, doUpdate = true) {
    const data = {
      way: this.props.way,
      name: this.props.data.def.get("id_name"),
      fieldName: this.props.data.name,
      type: "relate",
      value: item,
    };
    this.saveField(data, doUpdate);
  }
  openPopupList() {
    var self = this;
    const data = {
      module: this.getParentModule(),
      selectedActive: false
    };
    sAction.openRelatePopup(data, returnData => {
      self.selectItem(returnData);
      sAction.popupHide();
    });
  }
  newRecord() {
    const data = this.props.data;
    let module = data.get('def').get('parent_type_value');
    if (!module) {
      console.log('Invalid module');
      return;
    }

    sAction.popupDetail({
      module,
      record: "",
      saveCallback: ret => {
        const item = {
          id: ret.record,
          name: ret.name,
        };
        const data = {
          way: this.props.way,
          name: this.props.data.def.get("id_name"),
          fieldName: this.props.data.name,
          type: "relate",
          value: item,
          cancelEdit:true,
        };
        this.setState({
          id: item.id,
          name: item.name,
        });
        this.saveField(data, true);
        sAction.unLoad();
      },
      exitCallback: (data) => {},
    });
  }
  moduleChange(val) {
    if (val !== this.getParentModule()) {
      const data = {
        way: this.props.way,
        name: this.props.data.def.get("id_name"),
        fieldName: this.props.data.name,
        type: "parent",
        value: { id: null, name: null },
        parent_type: val,
        cancelEdit: false
      };
      this.setState({
        id: null,
        value: null
      });
      this.saveField(data, true, true);
    }
  }
  getOptions() {
    const data = this.props.data;
    const storedOptions = data.def;
    if (storedOptions.get("customOptions")) {
      this.setState({
        options: storedOptions.get("customOptions").toJS(),
        defaultValue: this.getParentModule()
      });
    } else {
      sAction.load();
      sAction.getParentFieldOptions(this.props.module, returnData => {
        sAction.unLoad();
        var options = [];
        returnData.forEach(option => {
          options.push({
            value: option,
            label: sAction.app_strings["moduleList"][option]
          });
        });
        this.setState({
          options: options,
          defaultValue: this.getParentModule()
        });
        sAction.dataSet(this.props.way + "/def/customOptions", options);
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.searchInterval);
  }
  componentWillMount() {
    this.getOptions();
  }

  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.ok;
    var inputClass = "form-control form-control-sm";
    var containerClass = "inputEditContainer";
    if (ok === false) {
      inputClass += " wrongInput";
    }

    var value = data.value;
    if (newRecord && (value === "" || value === undefined)) {
      value = "";
    }

    const searchResult = this.getSearchResult();
    const containerClassField = "inputContainer relateField";

    value = data.value;
    if (newRecord && (value === "" || value === undefined)) {
      value = "";
    }
    this.state.defaultValue = this.getParentModule();

    return (
      <div className={containerClass} data-fieldname={data.name}>
        <div className="detailViewParentModuleSelect">
          <Select
            onKeyDown={e => this.onKeyDown(e, "first")}
            options={this.state.options}
            onChange={e => this.moduleChange(e.target.value)}
            defaultValue={this.state.defaultValue}
          />
        </div>
        <div className={containerClassField}>
          <Input
            onKeyDown={e => this.onKeyDown(e, "last")}
            autoFocus={true}
            onKeyUp={event => this.keyUp(event)}
            onFocus={() => this.focus()}
            onBlur={e => this.onBlur(e)}
            myRef={this.input}
            className={inputClass}
            type="text"
            id={data.name}
            defaultValue={value}
            autoComplete="off"
          />
          {searchResult}
        </div>
        <div className="buttonContainer">
          <div
            tabIndex="1"
            onClick={() => this.openPopupList()}
            className="inputEditButton"
          >
            <div className={"icon-detailSearch"} />
          </div>
          <div
            tabIndex="2"
            onClick={() => this.deleteValue()}
            className=" inputEditButton"
          >
            <div className={"icon-detailCancel"} />
          </div>
        </div>
      </div>
    );
  }
}
