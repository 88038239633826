/**
 * Otevre popup okno pro napsani e-mailu
 * @param {object} data - email data {}, parametry viz DataConstructors.EmailCompose
 * @param {string} data.to_addrs - Adresati oddeleni carkou nebo strednikem
 * @param {string} data.emailTemplate - Sablona
 * @param {string} data.description_html - Telo emailu
 * @param {boolean} data.disable_recipient_change - True => nepujde zmenit adresaty
 * @param {string} data.customData - custom data
 * @param {string} data.parent_type - modul parenta
 * @param {string} data.parent_id - id parenta
 * @param {string} data.parent_name - nazev parenta
 */
export default function openEmailPopup (data = {}) {
  this.dsClear();
  this.dsAdd("set", "conf/popup/show", true);
  this.dsAdd("set", "conf/popup/content", "emailCompose");
  this.dsAdd("set", "conf/popup/data", data, "EmailCompose");
  this.dsProcess();
}