
import React, { Component } from 'react'

import InputField from '../inputFieldClass'
import InputText from "../../formElements/InputText";
import Radio from "../../formElements/Radio";
import TickBox from "../../formElements/TickBox";

import  sAction  from "sAction";
import {array} from "prop-types";


export default class Url extends InputField {
  constructor(){
    super();
    this.state = {
      state: "fine",
      // reg: /^[a-zA-ZÀ-ž\.\-\/\,\:\?\!\+\@ 0-9]+$/,
      reg: /^/,
    };
  }
  componentDidMount() {

  }
  render() {
    const data = this.props.data;
    const newRecord = this.props.newRecord;
    const ok = this.state.ok;
    // var inputClass = "form-control form-control-sm";
    var containerClass = "inputEditContainer";
    var error = false;
    if (ok === false) {
      //  inputClass += " wrongInput";
      error = true;
    }
    var urls = [""];

    if (data.value != null){
      urls = data.value.split("|")
    }

    var value = data.value;
    if (newRecord && (value == null || value == undefined)) {
      value = "";
    }

    var urlsCount = urls.length;

    var content = [];

    var astyle = {};
    urls.forEach((v, i) => {
      content.push(
        <tr key={v+i+urlsCount}>
          <td id={"websiteInput"+i}>
            <div className={containerClass} data-fieldname={data.name} >
              <div className="inputContainer">
                <InputText
                  autoFocus={true}
                  // onKeyUp={e => this.keyUpCall(e)}
                  // onKeyDown={e => this.onKeyDown(e)}
                  // myRef={this.input}
                  onBlur={this.onChange}
                  error={error}
                  defaultValue={v}
                  // value={v}
                  id={i.toString()}
                  name={i.toString()}
                />
              </div>

              {!newRecord && (
                <div className="buttonContainer">
                  <div
                    tabIndex="2"
                    onClick={() => this.save()}
                    className="inputEditButton"
                  >
                    <div className={"icon-detailConfrim"} />
                  </div>
                  <div
                    tabIndex="3"
                    onClick={() => this.deleteUrl(i)}
                    className=" inputEditButton"
                  >
                    <div className={"icon-detailCancel"} />
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        {1 > 0 && (
          <table className="DetailViewValue__emails">
            <tbody>
            {content}
            </tbody>
          </table>
        )}
        {/*Přidat e-mail*/}
        <a
          onClick={() => this.addUrl()}
          tabIndex="0"
          style={astyle}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              this.addUrl();
            }
          }}
        >
          + <span className="icon-link"/>
          {" "+sAction.translate("LNK_NEW_URL", "Contacts")}
        </a>
      </React.Fragment>
    );
  }

  save() {
    var val = sAction.dataGet(this.props.way + "/value");

    sAction.dsClear();

      sAction.dataSet(this.props.way + "/value", val);
      sAction.dataSet(this.props.prefix + "/changes/fields/website", val);
      sAction.dataSet(this.props.way + "/actEdit", false);
    sAction.dsProcess();

  }

  addUrl = () => {
    var val = sAction.dataGet(this.props.way + "/value")+"|";

    sAction.dsClear();

      sAction.dataSet(this.props.way + "/value", val);

    sAction.dsProcess();

  };
  onChange = (e) => {
    var val = sAction.dataGet(this.props.way + "/value");
    var tval = e.target.value;
    var tid = e.target.id;
    var urls = [""];
    if (val != null){
      urls = val.split("|")
    }
        urls[tid] = tval;
    var fval = urls.join("|");

    sAction.dsClear();

      sAction.dataSet(this.props.way + "/value", fval);
      sAction.dataSet(this.props.prefix + "/changes/fields/website", fval);

    sAction.dsProcess();
  };
  deleteUrl = (i) => {

    // document.getElementById("websiteInput"+i).remove();
    var val = sAction.dataGet(this.props.way + "/value");

    var urls = [""];
    if (val != null){
      urls = val.split("|")
    }

    urls[i] = "";

    var fval = urls.join("|").replace("||","|").replace(/\|$/, '');

    if (fval[0] == '|') {
      fval = fval.substring(1);
    }

    sAction.dsClear();

      sAction.dataSet(this.props.way + "/value", fval);
      sAction.dataSet(this.props.prefix + "/changes/fields/website", fval);

    sAction.dsProcess();
  };
}
