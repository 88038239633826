export default function emailChangeTemplate(id, props) {
  const parseData = {
    id,
    sendTo: props.data.to_addrs,
    parent_type: props.data.parent_type,
    parent_id: props.data.parent_id
  };

  this.load();

  this.rest.post("email/getEmailTemplate", parseData, data => {
    this.dsClear();
    if (data != null && typeof data === "object") {
      this.dsAdd("set", props.way + "/name", data.subject || "");
      this.dsAdd("set", props.way + "/emailTemplate", id);
      this.dsAdd(
        "set",
        props.way + "/description_html",
        this.emailAddVariableHighlights(
          this.htmlDecode(data.body_html + props.data.signature)
        )
      );
      this.dsAdd("set", props.way + "/updateEmailText", true);
    }
    this.dsAdd("set", "conf/load", false);
    this.dsProcess();
  });

  this.emailGetTemplateAttachments(id, props.way + "/attachments");
}
