export default function routeDetailView(data = null, paramData = null) {
  var id = this.getRecordId();
  var module = this.getModuleName();
  var prefix = "view";
  if (data != null) {
    if (data["id"] !== undefined) {
      id = data["id"];
    }
    if (data["module"] !== undefined) {
      module = data["module"];
    }
    if (data["prefix"] !== undefined) {
      prefix = data["prefix"];
    }
  }

  var content = {
    id,
    module
  };
  if (data !== null && data !== undefined) {
    content["type"] = data.type;

    if (data.type === "rightPanel") {
      paramData.quickcreate = true;
    }
  }
  this.rest.post("detail/" + module + "/" + id, paramData, data => {
    if (data.status == "error" && type == "rightPanel") {
      this.closeRightPanel();
    } else {
      if (module !== "EmailTemplates") {
        this.detailContent(data, content);
      } else {
        this.emailTemplatesContent(data, content);
      }
      var akce = {
        type: "PAGELOADDETAIL",
        content: content,
        prefix: prefix,
        function: self
      };
      this.dsAddCustom(akce);
      if (data['customData'] && !data['customData']['productData']) {
        this.dsAdd('set', prefix + '/customData', data['customData']);
      }
      if (data['predefinedFields']) {
        this.dsAdd('set', prefix + '/changes/fields', data['predefinedFields']);
      }
      if(data["customDataTop"]){
        this.dsAdd('set', prefix + '/customDataTop',data["customDataTop"]);
      }
      
      this.dsAdd('set', prefix + '/tags', data.tags);
      this.dsProcess();

      this.detailUrlParamsToFields(prefix);

      this.unLoad();
    }
  });
}
