import React from "react";
import TabContainer from "./TabContainer";
import PureComponent from "../pure";
import { MenuItem } from "@material-ui/core";
import InputText from "./InputText";
import  sAction  from "sAction";

export default class SelectWindow extends PureComponent {
    constructor(props) {
        super(props);

        var defaultValue = '';
        if (this.props.defaultValue != '' && this.props.defaultValue != null && this.props.defaultValue != undefined) {
            defaultValue = this.props.defaultValue;
        }
        const options = this.props.options
        var activeTab = 0;
        var index = 0;
        options.forEachObject((value, key) => {
            if(this.props.activeTabName == key) {
                activeTab = index;
            }
            index += 1;
        });
        this.state = {
            activeTab: activeTab,
            label: '',
            value: defaultValue,
            defaultValue: defaultValue,
            filter: '',
        };
        this.input = React.createRef();
    }
    
    onClose() {
        this.state.filter = '';
        this.props.onClose();
    }
    componentDidUpdate() {
        this.input.current ? this.input.current.focus() : null;
    }
    onChange(filter) {
        clearTimeout(this.interval);
        this.interval = setTimeout(() => {
            this.setState({ filter: filter });
        }, 300);
    }

    onClick(value, label) {
        this.setState({ label: label, value: value });
        this.props.onClick(value);
    }

    changeActivetab(value) {
        this.setState({ activeTab: value })
    }

    getContent(activeTab) {
        const options = this.props.options[activeTab];
        var orderedOptions = options.sort((a, b) => {
            var aLabel = a.label || a.value;
            var bLabel = b.label || b.value;
            return aLabel.localeCompare(bLabel, 'en', { sensitivity: 'base' });
        });

        var output = [];
        orderedOptions.forEach((item, key) => {
            if (this.state.defaultValue != '' && this.state.defaultValue == item.value) {
                this.state.defaultValue = '';
                this.state.label = item.label;
            }
            if (item.label) {
                var label = item.label || item.value || '';
                if (this.state.filter === '' || label.toLowerCase().includes(this.state.filter.toLowerCase())) {
                    output.push(
                        <MenuItem
                            key={key}
                            value={item.value}
                            onClick={(e) => this.onClick(item.value, item.label)}
                            className={item.value == this.state.value ? 'selected' : null}
                        >
                            {item.label || '{' + item.value + '}'} <span> ({item.value}) </span>
                        </MenuItem>
                    );
                }
            }
        });
        return output;
    }

    render() {
        const options = this.props.options;
        var tabs = [];
        options.forEachObject((value, key) => {
            tabs.push(key);
        });
        var renderMenu = (
            <TabContainer
                tabs={tabs}
                onChange={(e, value) => this.changeActivetab(value)}
                value={this.state.activeTab}
            />
        );
        var content = this.getContent(tabs[this.state.activeTab]);

        var dropdown = (
            <React.Fragment>
                <div className="customSelectWindow">
                    <div className="selectHeader">{renderMenu}</div>
                    <div className='selectInput'>
                        <InputText
                            onChange={e => this.onChange(e.target.value)}
                            autoFocus={true}
                            label={sAction.translate('LBL_REPORT_INPUT_FILTER')}
                            myRef={this.input}
                        />
                    </div>
                    <div className="selectContent">
                        {content}
                    </div>
                </div>
                <div className='datePickerBcContainer' onClick={() => this.onClose()}></div>
            </React.Fragment>
        );

        var label = this.state.label || null;

        return (
            <React.Fragment>
                <div className='selectLabel'>
                    {label}
                </div>
                {this.props.open == true && dropdown}
            </React.Fragment>
        )
    }
}