import React from "react";
import PureComponent from "../pure";
import sAction from "sAction";
import Tag from "./Tag";

export default class Tags extends PureComponent {
  deleteTag(record, key, way) {
    sAction.deleteTagRelate(record, key, way);

    return;
  }

  addTagRelate(way, record, module) {
    sAction.openAddTagRelatePopup(way, record, module);
  }

  findTagRelates(tag, record, module) {
    sAction.findTagRelatesOpenPopup(tag, record, module);
  }

  onRemove(tag, key, way) {
    sAction.deleteTagRelate(tag.get("relateId"), key, way);
  }

  render() {
    const props = this.props;
    const tags = this.props.tags;
    const way = this.props.way;
    const record = this.props.record;
    const module = this.props.module;
    var renderData = [];
    var i = 0;
    var b = 0;

    if (tags) {
      tags.forEach((tag, key) => {
        renderData.push(
          <Tag
            keyId={key}
            tag={tag}
            way={way}
            key={key}
            removable={true}
            onClick={e => this.findTagRelates(tag, record, module)}
            onRemove={(tag, key, way) => this.onRemove(tag, key, way)}
          />
        );
      });
    }

    return (
      <React.Fragment>
        <span className="tags">
          <span
            onClick={e => this.addTagRelate(way, record, module)}
            className="tagadd"
          >
            +
          </span>
          {renderData}
        </span>
      </React.Fragment>
    );
  }
}
