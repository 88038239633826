import React from "react";

import InputField from "../inputFieldClass";
import Select from "../../formElements/Select";
import  sAction  from "sAction";

export default class DefaultAcmDynamicEnum extends InputField {
  constructor() {
    super();
    this.state = {
      state: "fine",
      options: [],
    };
    this.input = React.createRef();
  }

  componentDidMount() {

  }

  check(value) {
    var required = this.props.data.def.get("required");
    if (required == undefined) {
      required = false;
    }
    var ok = true;
    if (required === true && value === "") {
      ok = false;
      this.setState({ ok });
    }
    return ok;
  }
  cancel(path) {
    console.log(path);
    if (path) {
      sAction.dataSet(path + "/actEdit", false);
    }
  }
  save(e,param = {}) {
    const value = e.target.value;

    if (this.check(value)) {
      const data = {
        way: this.props.way,
        name: this.props.data.name,
        value,
        ...param
      };

      if(e.keyCode == 13){
        data["cancelEdit"] = false;
      }

      this.saveField(data);
    }
  }
  render() {
    const data = this.props.data;
    const path = this.props.way + "/rows/" + this.props.rowIndex + "/records/" + this.props.recordIndex;
    var options = sAction.app_strings['dynamicEnum'][data.def.get('options')];
    var optionsToSelect = [];
    options.forEachObject((option, key) => {
      optionsToSelect.push({label: option, value: key});
    });
    const forceOpen = data.def.get("forceOpen") || this.props.listField || this.forceOpen;
    return (
      <Select
        options={optionsToSelect}
        defaultValue={data.value}
        onKeyDown={e => this.onKeyDown(e,null,false)}
        open={forceOpen === true ? forceOpen : false}
        autoFocus={forceOpen !== true ? true : forceOpen}
        onChange={e => this.save(e)}
        onClose={() => this.cancel(path)}
      />
    );
  }
}
