import React from "react";
import DateFnsUtils from '@date-io/date-fns';
import czLocale from "date-fns/locale/cs";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import sAction from "sAction"

const localeMap = {
    cz: czLocale,
};

export default class AcmDateTime extends React.Component {
    constructor(props) {
        super()
        console.log(props.value)
        this.state = {
            value: this.entryFormat(props.value),
            open: true,
        }
        this.input = React.createRef();
        this.dateTimeFormat = sAction.getDateFormat("datepicker")+" "+sAction.getTimeFormat("datepicker");
    }
    componentDidMount(props) {
        if (this.props.autoOpen == true) {
            this.input.current.click()
        }
    }
    entryFormat(date) {
        if (date == undefined || date == "") {
            return null
        }
        return date
    }
    onChange(date) {
        const dateObj = new Date(date)
        const month = dateObj.getMonth() < 9 ? "0" + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1
        const day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate()
        const hour = dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours()
        const minutes = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes()
        const returnDate = dateObj.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minutes + ":00";
        this.setState({ value: date, open: false })
        if (this.props.onChange != undefined) {
            date = date === null ? null : returnDate;
            this.props.onChange(date);
        }
    }
    open() {
        console.log("ted");
    }
    render() {
        console.log(this.state.value)
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap["cz"]}>
                <DateTimePicker
                    className={"AcmDate " + (this.props.className || null) + ' ' + (this.props.error ? 'error' : null)}
                    autoOk={true}
                    clearable
                    value={this.state.value}
                    placeholder="10.10.2018 20:20"
                    autoFocus={this.props.autoFocus ?? false}
                    onChange={date => this.onChange(date)}
                    onClose={() => this.props.onClose != undefined ? this.props.onClose() : {}}
                    cancelLabel={sAction.translate("LBL_DATEPICKER_CANCEL")}
                    okLabel={sAction.translate("LBL_DATEPICKER_OK")}
                    clearLabel={sAction.translate("LBL_DATEPICKER_CLEAR")}
                    todayLabel={sAction.translate("LBL_DATEPICKER_TODAY")}
                    showTodayButton={true}
                    ampm={false}
                    format={this.dateTimeFormat}
                    inputRef={this.input}
                    // error={this.props.error}
                />
            </MuiPickersUtilsProvider>
        )
    }
}